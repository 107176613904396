import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Text } from 'rebass';
import RouterLink from 'react-router-dom/Link';
import { svgStyle } from 'src/styles/helpers';
import PlusSvg from 'assets/svg/plus.svg';

const Button = styled(RouterLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 13rem;
  border: 2px dashed #06d1bd;
  border-radius: 4px;
  background-color: #ffffff;
  text-decoration: none;
  box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);
  outline: none;
`;

const Label = styled(Text)`
  transition: color 225ms ease;

  ${Button}:hover & {
    color: black;
  }
`;

const PlusIcon = styled(PlusSvg)`
  ${svgStyle};
  width: 2.2rem;
  height: 2.2rem;
`;

const BuildButton = ({ onClick, label, route }) => (
  <Button to={route} width={1} onClick={onClick}>
    <Box>
      <Text pb={1} color="secondary" textAlign="center">
        <PlusIcon />
      </Text>
      <Label as="span" color="text" fontFamily="heading" fontSize="1.8rem" fontWeight="semibold">
        {label}
      </Label>
    </Box>
  </Button>
);

BuildButton.propTypes = {
  onClick: PropTypes.func,
  route: PropTypes.string,
  label: PropTypes.string,
};

export default BuildButton;
