import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { Box, Flex, Text } from 'rebass';
import { Button } from 'src/components/elements/Button';

class ConfirmModal extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    modalContext: PropTypes.object,
    modalName: PropTypes.string,
    modalTitle: PropTypes.string,
    modalDescription: PropTypes.string,
  };

  render() {
    const { modalContext, modalName, modalTitle, modalDescription, t } = this.props;

    return (
      <Modal
        isOpen={modalContext[modalName] && true}
        showCloseButton={true}
        style={{ maxWidth: '65rem' }}
        onRequestClose={() => modalContext.toggleModal(modalName, false)}
      >
        <Box pt={3} pb={2} px={[1, 2, 2]}>
          <Text as="h4" fontSize={['1.4rem', '1.4rem', '2rem']} fontWeight="semibold" textAlign="center">
            {modalTitle}
          </Text>
          <Box pt={3} css="max-width: 42.8rem; margin: auto;">
            <Text pb={3} fontWeight="semibold">
              {modalDescription}
            </Text>
            <Flex pb={3} bg="white" justifyContent="space-between">
              <Button
                type="button"
                css="width: 100%; max-width: 16.5rem;"
                onClick={() => modalContext.toggleModal(modalName, false)}
              >
                {t('no')}
              </Button>
              <Button
                type="button"
                css="width: 100%; max-width: 22.8rem;"
                onClick={() => modalContext.executeModalAction(modalName)}
              >
                {t('yes')}
              </Button>
            </Flex>
          </Box>
        </Box>
      </Modal>
    );
  }
}

export default ConfirmModal;
