import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'src/styles/theme';
import styled from 'styled-components';

const getSize = ({ sm }) => {
  if (sm)
    return `
    padding-top: .5em;
    padding-bottom: .5em;
    font-size: 1.4rem;
    line-height: 1.5;
  `;

  return `
    padding-top: .7em;
    padding-bottom: .7em;
    font-size: 1.6rem;
    line-height: 1.5;
  `;
};

export const InputStyled = styled.input`
  display: inline-block;
  padding-right: 0;
  padding-left: 0;
  border: none;
  border-bottom: 1px solid ${theme.colors.inputBorder};
  outline: 0;
  ${props => getSize(props)};
  ${props => props.css};
`;

const InputSearchbar = ({ type, ...others }) => <InputStyled type={type} {...others} />;

InputSearchbar.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
};

export default InputSearchbar;
