import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import ProposedCollections from 'src/components/blocks/ProposedCollections';
import RecommendedCollections from 'src/components/blocks/RecommendedCollections';
import UserFolders from 'src/components/blocks/UserFolders';
import UserWelcome from 'src/components/blocks/UserWelcome';
import Heading from 'src/components/typography/Heading';
import { Container, Content } from 'src/components/utilities/Containers';
import { LoadingContext } from 'src/context';

const COLLECTIONS_LIMIT = 6;
export const FOLDERS_LIMIT = 6;

class Dashboard extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    dataRecommendations: PropTypes.object,
    dataSubscriptions: PropTypes.object,
    dataProposedCollections: PropTypes.object,
    userData: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, dataRecommendations, dataProposedCollections, dataSubscriptions, userData, history } = this.props;
    return (
      <Container css="padding-bottom: 3rem;">
        <Content pt={2}>
          <Heading fontSize={['1.6rem', '1.6rem', '2.6rem']} textAlign={['center', 'center', 'left']}>
            {t('welcome')} {userData.firstname}
          </Heading>
        </Content>
        <UserWelcome user={userData} />
        {dataRecommendations?.collections &&
          dataRecommendations.collections.results.length > 0 && (
            <RecommendedCollections
              collections={dataRecommendations.collections.results}
              showLinkButton={dataRecommendations.collections.count > COLLECTIONS_LIMIT}
            />
          )}
        {dataSubscriptions?.folders &&
          dataSubscriptions.folders.results.length > 0 && (
            <UserFolders
              folders={dataSubscriptions.folders.results}
              showLinkButton={dataSubscriptions.folders.count > FOLDERS_LIMIT}
            />
          )}
        {dataProposedCollections?.proposedCollections &&
          dataProposedCollections.proposedCollections.results.length > 0 && (
            <ProposedCollections
              proposalCollections={dataProposedCollections.proposedCollections.results}
            />
          )}
      </Container>
    );
  }
}

export default withNamespaces()(Dashboard);
