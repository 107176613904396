import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Image } from 'rebass';
import styled from 'styled-components';
import { AddressBookIcon, BadgeIcon, LogoutIcon, SettingsIcon, TrophyIcon } from './Icons';
import Item from './Item';

export const DropdownStyled = styled.div`
  position: relative;
  z-index: 99;
`;

const ButtonStyled = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #5a5a59;
  font-size: 1.4rem;
  font-weight: 600;
  outline: 0;
  cursor: pointer;
  ${props => props.css};
`;

export const Chevron = styled.span`
  &::before {
    content: '';
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: 0.6em;
    height: 0.6em;
    margin-left: 1.6rem;
    border-style: solid;
    border-width: 1px 1px 0 0;
    vertical-align: top;
    transform: rotate(135deg);
    transition: transform 0.2s ease;
    ${props =>
    props.open &&
    `
    transform: rotate(-45deg) translateY(4px) translateX(-4px);
    `};
    ${props => props.css};
  }
`;

export const MenuStyled = styled.div`
  position: absolute;
  top: 100%;
  right: -2.3rem;
  padding: 0.8rem 1.2rem 0.8rem 1rem;
  background-color: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(185, 185, 185, 0.38);
  ${props =>
    !props.open &&
    `
  display: none;
  `} &::after,
  &::before {
    content: '';
    position: absolute;
    right: 15px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: solid transparent;
    pointer-events: none;
  }
  &:after {
    margin-left: -12px;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 12px;
  }
  &:before {
    margin-left: -13px;
    border-color: rgba(243, 243, 243, 0);
    border-bottom-color: #f3f3f3;
    border-width: 13px;
  }
  ${props => props.css};
`;

class Dropdown extends Component {
  static propTypes = {
    children: PropTypes.any,
    label: PropTypes.string,
    icon: PropTypes.any,
    avatar: PropTypes.object,
    cssButton: PropTypes.any,
    cssChevron: PropTypes.any,
  };

  state = {
    open: false,
  };

  onButtonClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { children, label, avatar, icon, cssButton, cssChevron } = this.props;
    const { open } = this.state;

    return (
      <DropdownStyled>
        <ButtonStyled type="button" onClick={this.onButtonClick} css={cssButton}>
          {avatar ? (
            <Image
              css="width: 4rem; margin-right: 1rem;"
              src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${avatar.image}`}
            />
          ) : (
            icon
          )}
          {label}
          <Chevron open={open} css={cssChevron} />
        </ButtonStyled>
        <MenuStyled open={open}>{children}</MenuStyled>
      </DropdownStyled>
    );
  }
}

Dropdown.AddressBookIcon = AddressBookIcon;
Dropdown.BadgeIcon = BadgeIcon;
Dropdown.LogoutIcon = LogoutIcon;
Dropdown.SettingsIcon = SettingsIcon;
Dropdown.TrophyIcon = TrophyIcon;
Dropdown.Item = Item;

export default Dropdown;
