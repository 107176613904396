import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { breakpoints, theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import CheckSvg from 'assets/svg/check.svg';

const CheckIcon = styled(CheckSvg)`
  ${svgStyle};
  width: 2rem;
  height: 1.1rem;
`;

const Button = styled(Text)`
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 1.4rem 2.4rem;
  border: 1px;
  border-color: transparent;
  border-radius: .4rem;
  background-color: ${theme.colors.white};
  text-decoration: none;
  box-shadow: 0 2px 4px 1px rgba(148,140,140,0.2);
  color: ${props => (props.current ? theme.colors.secondary : props.valid ? theme.colors.primary : theme.colors.text)};
  outline: 0;
  width: 280px;
  transition: .2s;
  cursor: ${props => (props.isEdit ? 'pointer' : 'default')};

  .label {
    flex: 1;
  }

  .number {
    width: 10%;
  }

  .check {
    width: 10%;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.6rem;
  }
`;

class NavigationButton extends PureComponent {
  static propTypes = {
    current: PropTypes.bool,
    valid: PropTypes.bool,
    number: PropTypes.string,
    label: PropTypes.string,
    isEdit: PropTypes.bool
  };

  render() {
    const { current, valid, number, label, isEdit, ...props } = this.props;

    return (
      <Button
        as="button"
        type="button"
        fontFamily="heading"
        fontWeight="semibold"
        textAlign="center"
        current={current ? 'true' : null}
        valid={valid ? 'true' : null}
        isEdit={isEdit}
        {...props}
      >
        <Box as="span" className="number" fontSize={['2rem', '2rem', '2.4rem']}>
          {number}
        </Box>
        <Box as="span" className='label' pl={['1.4rem', '1.4rem', '2rem']} fontSize={['1.4rem', '1.4rem', '1.6rem']}>
          {label}
        </Box>
        <Flex as="span" className="check" alignItems="center" pl="1rem">
          {valid && 
            <CheckIcon />
          }
        </Flex>
      </Button>
    );
  }
}

export default NavigationButton;
