import gql from 'graphql-tag';

export const AVATAR_FRAGMENT = gql`
  fragment AvatarParts on Avatar {
    id
    image
    type
  }
`;

export const GET_AVATARS = gql`
  query GetAvatars($type: String!) {
    avatars(type: $type) {
      ...AvatarParts
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const UPLOAD_AVATAR = gql`
  mutation uploadAvatar($file: Upload!) {
    uploadAvatar(file: $file) {
      ...AvatarParts
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const REMOVE_AVATAR = gql`
  mutation removeAvatar($avatarId: ID!) {
    removeAvatar(avatarId: $avatarId) {
      ...AvatarParts
    }
  }
  ${AVATAR_FRAGMENT}
`;
