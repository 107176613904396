import gql from 'graphql-tag';

export const GET_LEVELS = gql`
  {
    levels {
      id
      name
    }
  }
`;
