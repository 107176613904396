import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { Mutation } from '@apollo/client/react/components';
import { TOGGLE_PUBLICATION_STATUS_COLLECTION } from 'src/queries/collections';
import { TOGGLE_PUBLICATION_STATUS_FOLDER } from 'src/queries/folders';
import { Flex, Text } from 'rebass';
import Toggle from 'src/components/elements/Toggle';
import Link from 'react-router-dom/Link';
import UpdateSvg from 'assets/svg/update.svg';
import { theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import { ModalContext } from 'src/context';
import Layout from 'src/Layout';

const EditLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.text};
  text-decoration: none;
  cursor: pointer;
  ${(props) => props.css};

  &:hover {
    color: ${theme.colors.secondary};
  }
`;

const UpdateIcon = styled(UpdateSvg)`
  ${svgStyle};
  width: 2.4rem;
  height: 2.2rem;
  margin-right: 1.8rem;
  fill: ${theme.colors.secondary};
`;

class Folder extends PureComponent {
  static contextType = ModalContext;
  static propTypes = {
    t: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isPublished: PropTypes.bool,
    cssContainer: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    cssEditLink: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  };

  render() {
    const { toggleModal } = this.context;
    const { t, id, isPublished, cssContainer, cssEditLink } = this.props;

    return (
      <Flex
        py="1.3rem"
        pl={['2rem', '2rem', '5%']}
        pr={['2rem', '2rem', '12%']}
        bg="white"
        justifyContent="space-between"
        css={cssContainer}
      >
        <Mutation mutation={TOGGLE_PUBLICATION_STATUS_FOLDER}>
          {(togglePublicationStatusFolder) => (
            <Toggle
              id={id}
              name={`toggle-publication-status-folder-${id}`}
              checked={isPublished}
              onChange={() => {
                if (!isPublished) {
                  togglePublicationStatusFolder({ variables: { folder: id } });
                } else {
                  toggleModal(Layout.MODAL_UNPUBLISH_FOLDER, () =>
                    togglePublicationStatusFolder({ variables: { folder: id } }),
                  );
                }
              }}
              defaultLabel={t('draft')}
              checkedLabel={t('public')}
            />
          )}
        </Mutation>
        <EditLink to={`/chaines/${id}/editer`} css={cssEditLink}>
          <UpdateIcon />
          <Text as="span" fontFamily="heading" fontSize="1.4rem" fontWeight="medium">
            {t('edit')}
          </Text>
        </EditLink>
      </Flex>
    );
  }
}

export const FolderAuthorActions = withNamespaces()(Folder);

class Collection extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isPublished: PropTypes.bool,
  };
  static contextType = ModalContext;

  render() {
    const { toggleModal } = this.context;
    const { t, id, isPublished } = this.props;

    return (
      <Flex
        py="1.3rem"
        pl={['2rem', '2rem', '5%']}
        pr={['2rem', '2rem', '12%']}
        bg="white"
        justifyContent="space-between"
      >
        <Mutation mutation={TOGGLE_PUBLICATION_STATUS_COLLECTION}>
          {(togglePublicationStatusCollection) => (
            <Toggle
              name={`toggle-publication-status-collection-${id}`}
              checked={isPublished}
              onChange={() => {
                if (!isPublished) {
                  togglePublicationStatusCollection({ variables: { collection: id } });
                } else {
                  toggleModal(Layout.MODAL_UNPUBLISH_COLLECTION, () =>
                    togglePublicationStatusCollection({ variables: { collection: id } }),
                  );
                }
              }}
              defaultLabel={t('draft')}
              checkedLabel={t('public')}
            />
          )}
        </Mutation>
        <EditLink to={`/series/${id}/editer`}>
          <UpdateIcon />
          <Text as="span" fontFamily="heading" fontSize="1.4rem" fontWeight="medium">
            {t('edit')}
          </Text>
        </EditLink>
      </Flex>
    );
  }
}

export const CollectionAuthorActions = withNamespaces()(Collection);
