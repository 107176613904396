import gql from 'graphql-tag';

export const GET_SUBJECTS = gql`
  {
    subjects {
      id
      name
    }
  }
`;

export const GET_SUBJECTS_OF_THE_FOLDERS_TO_WHICH_THE_USER_IS_SUBSCRIBED = gql`
  {
    subjectsOfTheFoldersToWhichTheUserIsSubscribed {
      id
      name
    }
  }
`;
