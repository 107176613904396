import styled from 'styled-components';
import { Text } from 'rebass';
import { theme } from 'src/styles/theme';

export default styled(Text)`
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding-left: 2rem;
  color: ${theme.colors.white};
  fontfamily: ${theme.fonts.heading};
  fontsize: 1.5rem;
  fontweight: ${theme.fontWeights.semibold};
  opacity: ${props => (props.open ? '.7' : 0)};
  text-transform: uppercase;
  transition: opacity 225ms ease;
`;
