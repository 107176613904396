import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Content } from 'src/components/utilities/Containers';
import { LinkButton } from 'src/components/elements/Button';
import { Text } from 'rebass';
import CollectionList from 'src/components/blocks/CollectionList';
import Title from 'src/components/typography/Title';
import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';
import RecommendationSvg from 'assets/svg/recommandations.svg';

const RecommendationIcon = styled(RecommendationSvg)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

class RecommendedCollections extends PureComponent {
  render() {
    const { collections } = this.props;

    return (
      <Content mt={3}>
        <Title sm icon={<RecommendationIcon />}>
          Mes séries recommandées
        </Title>
        <CollectionList data={collections} />
        {collections.length >= 3 && (
          <Text pt={1} textAlign="center">
            <LinkButton to="/series/recommandees" css="width: 100%; max-width: 22rem;">
              Voir plus de séries
            </LinkButton>
          </Text>
        )}
      </Content>
    );
  }
}

RecommendedCollections.propTypes = {
  collections: PropTypes.array,
};

export default RecommendedCollections;
