import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { PageLoading } from 'src/components/elements/Loading';

class LoadingProvider extends Component {
  static propTypes = {
    children: PropTypes.any,
    isLoading: PropTypes.bool,
  };

  state = {
    isLoading: true,
  };

  toggleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  addLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  removeLoading = () => {
    this.setState({
      isLoading: false,
    });
  };

  render() {
    return (
      <LoadingContext.Provider
        value={{
          isLoading: this.state.isLoading,
          toggleLoading: this.toggleLoading,
          addLoading: this.addLoading,
          removeLoading: this.removeLoading,
        }}
      >
        {this.props.children}
        <PageLoading isLoading={this.state.isLoading} />
      </LoadingContext.Provider>
    );
  }
}

export default LoadingProvider;
