import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Card from 'src/components/blocks/Card';
import BuildButton from 'src/components/elements/BuildButton';
import { Col, Grid } from 'src/components/utilities/Grids';

class CollectionList extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    showBuildButton: PropTypes.bool,
    editableCards: PropTypes.bool,
    author: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    toggleCollectionInFolder: PropTypes.func,
    collectionsOfFolders: PropTypes.array,
    marginTopContainer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    stateRedirect: PropTypes.object,
  };

  static defaultProps = {
    marginTopContainer: 2,
  };

  render() {
    const {
      data,
      showBuildButton,
      editableCards,
      author,
      toggleCollectionInFolder,
      collectionsOfFolders,
      marginTopContainer,
      stateRedirect,
    } = this.props;

    return (
      <Grid mt={marginTopContainer}>
        {showBuildButton && (
          <Col width={[1 / 1, 1 / 1, 1 / 2, 1 / 3]} pb={2}>
            <BuildButton route={'/series/creer'} label="Créer une série" />
          </Col>
        )}
        {data.map((values, key) => {
          return (
            <Col key={key} width={[1 / 1, 1 / 1, 1 / 2, 1 / 3]} pb={2}>
              <Card.Collection
                {...values}
                stateRedirect={stateRedirect}
                editable={editableCards && author === values.author.id}
                toggleCollectionInFolder={toggleCollectionInFolder}
                inFolder={collectionsOfFolders && collectionsOfFolders.includes(values.id)}
              />
            </Col>
          );
        })}
      </Grid>
    );
  }
}

export default CollectionList;
