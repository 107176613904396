import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Col } from 'src/components/utilities/Grids';
import Card from 'src/components/blocks/Card';
import BuildButton from 'src/components/elements/BuildButton';
import { theme } from 'src/styles/theme';

class FolderList extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    showBuildButton: PropTypes.bool,
    editableCards: PropTypes.bool,
    author: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showSubscribeButton: PropTypes.bool,
    marginTopContainer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    marginTopContainer: 2,
  };

  render() {
    const { data, showBuildButton, editableCards, author, showSubscribeButton, marginTopContainer } = this.props;

    return (
      <Grid mt={marginTopContainer}>
        {showBuildButton && (
          <Col width={[1 / 1, 1 / 1, 1 / 2, 1 / 3]} pb={2}>
            <BuildButton route={'/chaines/creer'} label="Créer une chaîne" />
          </Col>
        )}
        {data.map((values, key) => {
          return (
            <Col key={key} width={[1 / 1, 1 / 1, 1 / 2, 1 / 3]} pb={2}>
              <Card.Folder
                {...values}
                editable={editableCards && author === values.author.id}
                showSubscribeButton={showSubscribeButton}
                contentBg={theme.colors.gray100}
              />
            </Col>
          );
        })}
      </Grid>
    );
  }
}

export default FolderList;
