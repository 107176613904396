import gql from 'graphql-tag';
import { COLLECTION_FRAGMENT, FAVORITE_FRAGMENT } from './collections';

export const FOLDER_FRAGMENT = gql`
  fragment FolderParts on Folder {
    id
    title
    description
    isPublished
    isOfficial
    schoolSubject {
      id
      name
      color
    }
    schoolDegrees {
      id
      name
    }
    countCollections
    author {
      id
      firstname
      lastname
      roles
    }
  }
`;

const SUBSCRIPTION_FRAGMENT = gql`
  fragment SubscriptionPart on UserLinkFolder {
    id
  }
`;

export const GET_FOLDER = gql`
  query GetFolder(
    $id: ID!
    $collectionLimit: Int
    $collectionPage: Int = 1
    $collectionsFilterBy: [FilterBy]
    $collectionsOrderBy: [OrderBy] = [{ property: "updated", order: "DESC" }]
    $withCollections: Boolean = true
  ) {
    folder(id: $id) {
      ...FolderParts
      linkUsers {
        ...SubscriptionPart
      }
      collections(
        limit: $collectionLimit
        page: $collectionPage
        filterBy: $collectionsFilterBy
        orderBy: $collectionsOrderBy
      ) @include(if: $withCollections) {
        count
        results {
          ...CollectionParts
          linkUsers {
            ...FavoriteParts
            status
            user {
              id
            }
          }
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT}
  ${FOLDER_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
  ${FAVORITE_FRAGMENT}
`;

export const GET_FOLDERS = gql`
  query GetFolders(
    $page: Int = 1
    $limit: Int
    $filterBy: [FilterBy]
    $orderBy: [OrderBy]
    $withCollections: Boolean = false
    $collectionLimit: Int = 20
    $collectionPage: Int = 1
    $collectionsFilterBy: [FilterBy]
    $collectionsOrderBy: [OrderBy] = [{ property: "updated", order: "DESC" }]
  ) {
    folders(limit: $limit, page: $page, filterBy: $filterBy, orderBy: $orderBy) {
      count
      results {
        ...FolderParts
        linkUsers {
          ...SubscriptionPart
        }
        collections(
          limit: $collectionLimit
          page: $collectionPage
          filterBy: $collectionsFilterBy
          orderBy: $collectionsOrderBy
        ) @include(if: $withCollections) {
          count
          results {
            ...CollectionParts
          }
        }
        countCollectionsUnseen
      }
    }
  }
  ${COLLECTION_FRAGMENT}
  ${FOLDER_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
`;

// get three folders for each school subject in a particular school degree
export const GET_OFFICIAL_FOLDERS = gql`
  query GetOfficialFolders($schoolDegree: ID!) {
    officialFolders(schoolDegree: $schoolDegree) {
      ...FolderParts
      linkUsers {
        ...SubscriptionPart
      }
    }
  }
  ${FOLDER_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
`;

export const TOGGLE_SUBSCRIPTION_FOLDER = gql`
  mutation toggleSubscriptionFolder($folder: ID!) {
    toggleSubscriptionFolder(folder: $folder) {
      id
      linkUsers {
        ...SubscriptionPart
      }
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`;

export const TOGGLE_PUBLICATION_STATUS_FOLDER = gql`
  mutation togglePublicationStatusFolder($folder: ID!) {
    togglePublicationStatusFolder(folder: $folder) {
      id
      isPublished
    }
  }
`;

export const ADD_FOLDER = gql`
  mutation AddFolder($input: AddFolderInput) {
    AddFolder(input: $input) {
      id
      title
    }
  }
`;

export const SET_COLLECTIONS_OF_FOLDER = gql`
  mutation setCollectionsOfFolder($folder: ID!, $collections: [ID!]) {
    setCollectionsOfFolder(folder: $folder, collections: $collections) {
      id
    }
  }
`;

export const REMOVE_FOLDER = gql`
  mutation RemoveFolder($folder: ID!) {
    RemoveFolder(folder: $folder)
  }
`;

export const ADD_COLLECTION_TO_FOLDERS = gql`
  mutation addCollectionToFolders($collection: ID!, $folders: [ID!]) {
    addCollectionToFolders(collection: $collection, folders: $folders) {
      id
      folders {
        id
        title
      }
    }
  }
`;
