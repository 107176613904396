import gql from 'graphql-tag';

import { CARD_FRAGMENT } from 'src/queries/cards';
import { AVATAR_FRAGMENT } from './avatars';

export const COLLECTION_FRAGMENT = gql`
  fragment CollectionParts on Collection {
    id
    serialNumber
    title
    description
    isPublished
    schoolSubject {
      id
      name
      color
    }
    schoolDegrees {
      id
      name
      schoolLevel {
        id
        settings {
          brioTimerEasy
          brioTimerMedium
          brioTimerHard
          memoTimerEasy
          memoTimerMedium
          memoTimerHard
        }
      }
    }
    isOfficial
    countCards
    author {
      id
      firstname
      lastname
      roles
      avatar {
        ...AvatarParts
      }
    }
    updated
    assoMode
    memoMode
    typoMode
    brioMode
    ignoreCase
    ignoreOrder
    ignoreSpaces
    ignoreCharacters
  }
  ${AVATAR_FRAGMENT}
`;

export const FAVORITE_FRAGMENT = gql`
  fragment FavoriteParts on UserLinkCollection {
    id
    isFavorite
  }
`;

export const ADD_COLLECTION = gql`
  mutation AddCollection($input: AddCollectionInput) {
    AddCollection(input: $input) {
      ...CollectionParts
      cards {
        ...CardFragment
      }
    }
  }
  ${COLLECTION_FRAGMENT}
  ${CARD_FRAGMENT}
`;

export const GET_COLLECTION = gql`
  query GetCollection($id: ID!, $withUserLinkCard: Boolean = false) {
    collection(id: $id) {
      ...CollectionParts
      cards {
        ...CardFragment
        userLinkCard @include(if: $withUserLinkCard) {
          id
          user {
            id
            username
          }
          status
          failed
        }
      }
      linkUsers {
        ...FavoriteParts
        status
        user {
          id
        }
      }
      folders {
        id
        title
      }
    }
  }
  ${COLLECTION_FRAGMENT}
  ${CARD_FRAGMENT}
  ${FAVORITE_FRAGMENT}
`;

// get collections
export const GET_COLLECTIONS = gql`
  query GetCollections($page: Int = 1, $limit: Int, $filterBy: [FilterBy], $orderBy: [OrderBy], $withCards: Boolean = false) {
    collections(page: $page, limit: $limit, filterBy: $filterBy, orderBy: $orderBy) {
      count
      results {
        ...CollectionParts
        cards @include(if: $withCards) {
          ...CardFragment
        }
        linkUsers {
          ...FavoriteParts
          status
          user {
            id
          }
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT}
  ${CARD_FRAGMENT}
  ${FAVORITE_FRAGMENT}
`;

// get three collections for each school subject in a particular school degree
export const GET_OFFICIAL_COLLECTIONS = gql`
  query GetOfficialCollections($schoolDegree: ID!) {
    officialCollections(schoolDegree: $schoolDegree) {
      ...CollectionParts
      linkUsers {
        ...FavoriteParts
        status
        user {
          id
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT}
  ${FAVORITE_FRAGMENT}
`;

export const TOGGLE_FAVORITE_COLLECTION = gql`
  mutation toggleFavoriteCollection($collection: ID!) {
    toggleFavoriteCollection(collection: $collection) {
      id
      linkUsers {
        ...FavoriteParts
        status
        user {
          id
        }
      }
    }
  }
  ${FAVORITE_FRAGMENT}
`;

export const TOGGLE_PUBLICATION_STATUS_COLLECTION = gql`
  mutation togglePublicationStatusCollection($collection: ID!) {
    togglePublicationStatusCollection(collection: $collection) {
      id
      isPublished
    }
  }
`;

export const SET_COLLECTION_SEEN = gql`
  mutation setCollectionSeen($collection: ID!) {
    setCollectionSeen(collection: $collection) {
      id
    }
  }
`;

export const SET_COLLECTION_STATUS = gql`
  mutation setCollectionStatus($collection: ID!, $status: String!) {
    setCollectionStatus(collection: $collection, status: $status) {
      id
      linkUsers {
        id
        status
      }
    }
  }
`;

export const SAVE_SCORE = gql`
  mutation saveScore($score: Int!, $time: Int!, $errors: Int!, $game: String!, $collection: ID!, $duel: ID) {
    saveScore(score: $score, time: $time, errors: $errors, game: $game, collection: $collection, duel: $duel) {
      id
    }
  }
`;

export const FINISH_GAME = gql`
  mutation finishGame($collection: ID!, $game: String!, $won: Boolean!, $score: Int!, $time: Int!) {
    finishGame(collection: $collection, game: $game, won: $won, score: $score, time: $time)
  }
`;

export const START_GAME = gql`
  mutation startGame($collection: ID!, $game: String!) {
    startGame(collection: $collection, game: $game)
  }
`;

export const COLLECTIONS_ORDER_BY = {
  newest: {
    property: 'updated',
    order: 'DESC',
  },
  oldest: {
    property: 'updated',
    order: 'ASC',
  },
  alphabetical: {
    property: 'title',
    order: 'ASC',
  },
  reverseAlphabetical: {
    property: 'title',
    order: 'DESC',
  },
};

export const REMOVE_COLLECTION = gql`
  mutation removeCollection($collection: ID!) {
    removeCollection(collection: $collection)
  }
`;
