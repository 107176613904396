"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
//@ts-nocheck
var components_1 = require("@apollo/client/react/components");
var component_1 = require("@loadable/component");
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var Layout_1 = require("src/Layout");
var SimpleLayout_1 = require("src/SimpleLayout");
var users_1 = require("src/queries/users");
var context_1 = require("src/context");
var roles_1 = require("src/utils/roles");
var router_1 = require("src/routing/router");
var routes_1 = require("src/routing/routes");
var StudentDashboard = (0, component_1.default)(function () {
    return Promise.resolve().then(function () { return require(/* webpackChunkName: 'studentDashboard' */ "src/pages/StudentDashboard"); });
});
var StudentOnboarding = (0, component_1.default)(function () {
    return Promise.resolve().then(function () { return require(/* webpackChunkName: 'studentOnboarding' */ "./pages/Onboarding/Student"); });
});
var TeacherDashboard = (0, component_1.default)(function () { return Promise.resolve().then(function () { return require("src/pages/TeacherDashboard"); }); });
var TeacherOnboarding = (0, component_1.default)(function () {
    return Promise.resolve().then(function () { return require(/* webpackChunkName: 'studentOnboarding' */ "./pages/Onboarding/Teacher"); });
});
var ProfileOnboarding = (0, component_1.default)(function () {
    return Promise.resolve().then(function () { return require(/* webpackChunkName: 'profileOnboarding' */ "./pages/Onboarding/Student/Profile"); });
});
function handleRedirect(isTeacher, onlyForTeacher, onlyForStudent) {
    if (isTeacher && onlyForStudent) {
        return true;
    }
    else if (!isTeacher && onlyForTeacher) {
        return true;
    }
    return false;
}
function LoggedInit() {
    return (React.createElement(components_1.Query, { query: users_1.GET_CURRENT_USER }, function (_a) {
        var loading = _a.loading, error = _a.error, data = _a.data;
        if (loading)
            return React.createElement("div", null);
        if (error)
            return React.createElement("p", null, "Error :(");
        var user = data.me;
        var isTeacher = (0, roles_1.hasTeacherRole)(user);
        var HomeComponent = null;
        var HomeLayout = SimpleLayout_1.default;
        var showOnboarding = false;
        if (isTeacher) {
            if (user.schoolSubjects.length === 0 ||
                user.avatar === null ||
                user.hasBeenReset) {
                showOnboarding = true;
                HomeComponent = TeacherOnboarding;
            }
            else {
                HomeComponent = TeacherDashboard;
                HomeLayout = Layout_1.default;
            }
        }
        else {
            if (user.schoolLevel === null ||
                user.schoolDegree === null ||
                user.hasBeenReset) {
                showOnboarding = true;
                HomeComponent = StudentOnboarding;
            }
            else if (user.avatar === null) {
                showOnboarding = true;
                HomeComponent = ProfileOnboarding;
            }
            else {
                HomeComponent = StudentDashboard;
                HomeLayout = Layout_1.default;
            }
        }
        return (React.createElement(context_1.UserContext.Provider, { value: user },
            React.createElement(react_router_dom_1.Switch, null,
                Object.keys(router_1.default).map(function (key, index) {
                    var _a = router_1.default[key], path = _a.path, component = _a.component, onlyForTeacher = _a.onlyForTeacher, onlyForStudent = _a.onlyForStudent, rest = __rest(_a, ["path", "component", "onlyForTeacher", "onlyForStudent"]);
                    return (React.createElement(routes_1.AppRoute, __assign({ key: index, path: path, showOnboarding: typeof rest.showOnboarding !== "undefined"
                            ? rest.showOnboarding
                            : showOnboarding, user: user, component: component, redirect: handleRedirect(isTeacher, onlyForTeacher, onlyForStudent) }, rest)));
                }),
                React.createElement(routes_1.AppRoute, { exact: true, path: "/", user: user, layout: HomeLayout, component: HomeComponent }),
                React.createElement(react_router_dom_1.Redirect, { from: "*", to: "/" }))));
    }));
}
exports.default = LoggedInit;
