import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import { svgStyle } from 'src/styles/helpers';
import CheckSvg from 'assets/svg/checked-chip.svg';

const CheckIcon = styled(CheckSvg)`
  ${svgStyle};
  width: 3.2rem;
  height: 3.2rem;
`;

const Container = styled(Box)`
  position: relative;
  width: 9.2rem;
  ${props => props.css};
`;

class Avatar extends PureComponent {
  static propTypes = {
    avatar: PropTypes.object,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    removeButton: PropTypes.any,
  };

  render() {
    const { avatar, isSelected, onClick, removeButton, ...others } = this.props;
    const avatarStyle =
      avatar.type === 'TYPE_CUSTOM'
        ? {
            borderRadius: '46px',
            width: '92px',
            height: '92px',
          }
        : {};

    return (
      <Container {...others}>
        <img
          onClick={onClick}
          src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${avatar.image}`}
          style={{ cursor: 'pointer', ...avatarStyle }}
        />
        {isSelected && (
          <Box css="position: absolute; right: .3rem; bottom: .3rem;">
            <CheckIcon />
          </Box>
        )}
        {removeButton}
      </Container>
    );
  }
}

export default Avatar;
