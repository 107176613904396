import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass';
import { Container } from 'src/components/utilities/Containers';
import Footer from 'src/components/blocks/Footer';
import { breakpoints, theme } from 'src/styles/theme';
import { LogoIcon } from 'src/components/blocks/Header/Icons';
import { Grid, Col } from 'src/components/utilities/Grids';
import { withNamespaces } from 'react-i18next';

const LayoutStyled = styled.main`
  position: relative;
  z-index: 2;
  flex-grow: 1;
  display: flex;
  padding-left: 0;
  pointer-events: none;
  will-change: transform;
`;

const LayoutInnerStyled = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  min-height: 0;
  background-color: ${theme.colors.white};
  pointer-events: auto;
`;

const TopHeader = styled(Grid)`
  position: relative;
  height: 2rem;
  background-color: #fafafa;
  color: #888888;
`;

const headerLinkStyled = {
  color: 'text',
  fontSize: '1.3rem',
  css: {
    textDecoration: 'none',
  },
};

const HeaderStyled = styled(Box)`
  position: relative;
  height: 4rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(234, 234, 234, 0.5);

  @media (min-width: ${breakpoints.md}) {
    height: 8rem;
  }
`;

class SimpleLayout extends Component {
  static propTypes = {
    t: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
  };

  render() {
    const { children, className, t } = this.props;

    return (
      <LayoutStyled className={className}>
        <LayoutInnerStyled>
          <TopHeader>
            <Col width={1 / 2} justifyContent={'flex-start'} pl={3}>
              <Text {...headerLinkStyled} as="a" href={`https://www.ge.ch/`}>
                {t('landing.header.canton')}
              </Text>
              <Text fontSize={'1.3rem'}> | </Text>
              <Text
                {...headerLinkStyled}
                as="a"
                href={`https://www.ge.ch/organisation/departement-instruction-publique-formation-jeunesse-dip`}
              >
                {t('landing.header.dip')}
              </Text>
            </Col>
            <Col width={1 / 2} justifyContent={'flex-end'} pr={2}>
              <Text {...headerLinkStyled} as="a" href={`${process.env.BASENAME}login`}>
                {t('login')}
              </Text>
              <Text fontSize={'1.3rem'}> | </Text>
              <Text {...headerLinkStyled} as="a" href={`https://edu.ge.ch/sem/node/2196`}>
                {t('landing.header.impressum')}
              </Text>
            </Col>
          </TopHeader>
          <HeaderStyled>
            <Container css="height: 100%;">
              <Flex alignItems="center" justifyContent="center" css="height: 100%;">
                <LogoIcon />
              </Flex>
            </Container>
          </HeaderStyled>
          <React.Fragment>
            <Flex css="flex: 1 1 auto; width: 100%;">{children}</Flex>
            <Footer />
          </React.Fragment>
        </LayoutInnerStyled>
      </LayoutStyled>
    );
  }
}

export default withNamespaces()(SimpleLayout);
