import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';
import { theme } from 'src/styles/theme';

function createSchoolDegreeLabel(schoolDegrees) {
  const label = [];
  schoolDegrees.forEach(schoolDegree => {
    label.push(schoolDegree.name);
  });

  return label.join(' et ');
}

const Notification = ({ notification }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    width="2.2rem"
    bg="secondary"
    color="white"
    fontSize="1.2rem"
    css={`
      height: 2.2rem;
      border-radius: 50%;
      font-weight: ${theme.fontWeights.semibold};
    `}
  >
    {notification}
  </Flex>
);

Notification.propTypes = {
  notification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export const CollectionHeader = ({ schoolDegrees, schoolSubject }) => (
  <Text as="span" pb="1.4rem" fontSize="1.2rem">
    {`${createSchoolDegreeLabel(schoolDegrees)} - ${schoolSubject}`}
  </Text>
);

CollectionHeader.propTypes = {
  schoolDegrees: PropTypes.array.isRequired,
  schoolSubject: PropTypes.string.isRequired,
};

export const FolderHeader = ({ label, notification, color }) => (
  <Flex alignItems="center" justifyContent="space-between" pb="1rem">
    <Text as="span" pb=".4rem" color={color} fontSize="1.2rem">
      {label}
    </Text>
    {!!notification && <Notification notification={notification} />}
  </Flex>
);

FolderHeader.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  notification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FolderHeader.defaultProps = {
  color: theme.colors['others'],
};
