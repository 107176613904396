import PropTypes from 'prop-types';
import React from 'react';
import Link from 'react-router-dom/Link';
import Truncate from 'react-truncate';
import { Text } from 'rebass';
import Heading from 'src/components/typography/Heading';
import { theme } from 'src/styles/theme';
import styled from 'styled-components';

const Container = ({ bg, to, toggleCollectionInFolder, children, state }) =>{
  const styles = {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    padding: '1.2rem',
    backgroundColor: bg || theme.colors.white,
    color: theme.colors.text,
    textDecoration: 'none'
  };

  return (
    toggleCollectionInFolder ?
      <div style={styles}>
        {children}
      </div>
    :
      <Link to={{pathname: to, state: state}} style={styles}>
        {children}
      </Link>
  );
};

const Content = ({ to, state, header, title, description, author, bg, toggleCollectionInFolder }) => (
  <Container
    to={to}
    state={state}
    toggleCollectionInFolder={toggleCollectionInFolder}
    bg={bg}
  >
    {header}
    <Heading as="h5" pb="1.8rem" lineHeight="1.4" css="display: flex; flex: 1 1 auto; align-items: center;">
      <Truncate lines={2} ellipsis="...">
        {title}
      </Truncate>
    </Heading>
    {description && (
      <Text as="span" fontSize="1.2rem">
        <Truncate lines={2} ellipsis="...">
          {description}
        </Truncate>
      </Text>
    )}
    {author && (
      <Text as="span" pt=".6rem" fontSize="1.2rem" fontWeight="semibold">
        {author}
      </Text>
    )}
  </Container>
);

Content.propTypes = {
  to: PropTypes.string,
  header: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  bg: PropTypes.string,
  state: PropTypes.object,
};

export default Content;
