import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';

import { Flex } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import { Container as ContainerStyle, Content } from 'src/components/utilities/Containers';
import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';
import SearchSvg from 'assets/svg/search.svg';
import CloseSvg from 'assets/svg/close.svg';

const Container = styled(ContainerStyle)`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const SearchIcon = styled(SearchSvg)`
  ${svgStyle};
  width: auto;
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    width: 3rem;
    height: 3.2rem;
  }
`;

const CloseIcon = styled(CloseSvg)`
  ${svgStyle};
  width: auto;
  height: 0.8em;

  @media (min-width: ${breakpoints.md}) {
    height: 1.2em;
  }
`;

const Button = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  ${props => props.css};
`;

const Input = styled.input`
  flex: 1 1 100%;
  min-height: 3.2rem;
  padding: 0;
  border: none;
  font-size: 1.4rem;
  line-height: 1.4;
  outline: 0;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.8rem;
  }
`;

class Searchbar extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    toggleSearchbarMobile: PropTypes.func,
  };

  state = {
    searchTerm: '',
  };

  search = e => {
    e.preventDefault();
    this.props.history.push(`/recherche/${this.state.searchTerm}`);
  };

  render() {
    const { t } = this.props;
    const { searchTerm } = this.state;

    return (
      <Container as="form" onSubmit={this.search}>
        <Content css="display: flex; align-items: center; width: 100%;">
          <Grid flex="1">
            <Col flex="0 0 auto">
              <Button as="button" type="button" onClick={() => this.props.toggleSearchbarMobile()} css="width: 3rem;">
                <CloseIcon />
              </Button>
            </Col>
            <Col flex="1" pl={1}>
              <Input
                type="search"
                name="search"
                results={5}
                placeholder={t('header.searchPlaceholder')}
                value={searchTerm}
                onChange={e => this.setState({ searchTerm: e.target.value })}
                required={true}
              />
            </Col>
            <Col flex="0 0 auto">
              <Button as="button" type="submit" onClick={this.search}>
                <SearchIcon />
              </Button>
            </Col>
          </Grid>
        </Content>
      </Container>
    );
  }
}

export default withRouter(withNamespaces()(Searchbar));
