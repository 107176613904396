import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import { Flex, Text } from 'rebass';
import { svgStyle } from 'src/styles/helpers';
import CortexSvg from 'assets/svg/brain.svg';
import loadingAnimation from 'assets/json/loading.json';

const CortexIcon = styled(CortexSvg)`
  ${svgStyle};
  position: absolute;
  top: calc(50% - 3rem);
  left: calc(50% - 3rem);
  width: 6rem;
  height: 6rem;
`;

const defaultAnimationOptions = {
  renderer: 'svg',
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    scaleMode: 'scale',
    preserveAspectRatio: 'xMinYMin',
  },
};

const PageLoadingContainer = styled(Flex)`
  position: fixed;
  z-index: ${props => (props.isLoading ? '100' : '-100')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: ${props => (props.isLoading ? 0.8 : 0)};
  pointer-events: ${props => (props.isLoading ? 'initial' : 'none')};
`;

const ContentLoadingContainer = styled(Flex)`
  position: relative;
  flex: 1 1 auto;
`;

const Loading = () => (
  <Text color="secondary">
    <CortexIcon />
  </Text>
);

export const PageLoading = ({ isLoading }) => (
  <PageLoadingContainer alignItems="center" justifyContent="center" bg="white" isLoading={isLoading}>
    <Lottie options={defaultAnimationOptions} width={320} height={245} isStopped={false} isPaused={false} />
    <Loading />
  </PageLoadingContainer>
);

PageLoading.propTypes = {
  isLoading: PropTypes.bool,
};

export const ContentLoading = () => (
  <ContentLoadingContainer
    alignItems="center"
    justifyContent="center"
    bg="white"
    css="position: relative; flex: 1 1 auto;"
  >
    <Lottie options={defaultAnimationOptions} width={320} height={245} isStopped={false} isPaused={false} />
    <Loading />
  </ContentLoadingContainer>
);
