import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from '@apollo/client/react/components';
import { adopt } from 'react-adopt';
import { GET_AVATAR_LEVELS } from 'src/queries/avatarLevels';
import { GET_CURRENT_USER, UPDATE_USER } from 'src/queries/users';

import Content from './Content';

class ProfileContainer extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
  };

  isContentLoaded = false;
  state = {
    selectedAvatar: this.props.history.location.state ? this.props.history.location.state.currentAvatar : null,
  };

  selectAvatar = avatar => {
    this.setState({ selectedAvatar: avatar.id });
  };

  removeFriend = (user, oldFriend, updateUser) => () => {
    return updateUser({
      variables: {
        input: {
          friends: [...user.friends.filter(friend => friend.id !== oldFriend.id).map(friend => ({ id: friend.id }))],
        },
      },
    });
  };

  onSubmit = updateUser => {
    const { history } = this.props;

    return updateUser({
      variables: {
        input: {
          avatar: { id: this.state.selectedAvatar },
        },
      },
    })
      .then(() => {
        history.push(`/`);
      })
      .catch(errors => {
        console.log(errors);
      });
  };

  render() {
    const { selectedAvatar } = this.state;

    /*eslint-disable */
    const queries = {
      me: ({ render }) => (
        <Query query={GET_CURRENT_USER} variables={{ withFriends: true }}>
          {render}
        </Query>
      ),
      avatarLevels: ({ render }) => (
        <Query query={GET_AVATAR_LEVELS} variables={{ score: 0, type: 'TYPE_STUDENT' }}>
          {render}
        </Query>
      ),
      updateUser: ({ render }) => <Mutation mutation={UPDATE_USER}>{render}</Mutation>,
    };

    const Composed = adopt(queries);
    /*eslint-enable */

    return (
      <Composed>
        {({ avatarLevels, updateUser, me }) => {
          if ((avatarLevels.loading || updateUser.loading || me.loading) && !this.isContentLoaded) return <div />;
          if (avatarLevels.error || updateUser.error || me.error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          const userData = me.data.me;
          const hasFriends = userData && userData.friends.length > 0;
          this.userAvatar = userData && userData.avatar ? userData.avatar.id : null;
          const currentAvatar = selectedAvatar || this.userAvatar;
          const availableAvatarLevels =
            avatarLevels.data.avatarLevels && avatarLevels.data.avatarLevels.filter(obj => obj.score <= userData.score);

          let avatars = [];
          for (var avatarLevel of availableAvatarLevels) {
            avatars = avatars.concat(avatarLevel.avatars);
          }

          return (
            <Content
              removeFriend={this.removeFriend}
              selectAvatar={this.selectAvatar}
              onSubmit={this.onSubmit}
              avatars={avatars}
              currentAvatar={currentAvatar}
              hasFriends={hasFriends}
              updateUser={updateUser}
              userData={userData}
            />
          );
        }}
      </Composed>
    );
  }
}

export default ProfileContainer;
