import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import RouterLink from 'react-router-dom/Link';
import { Text } from 'rebass';
import { theme } from 'src/styles/theme';
import styled, { css } from 'styled-components';

const linkStyle = css`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: ${theme.colors.text};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

const Link = styled(RouterLink)`
  ${linkStyle};
  ${props => props.css};
`;

const LinkHref = styled.a`
  ${linkStyle};
  ${props => props.css};
`;

class Item extends PureComponent {
  static propTypes = {
    icon: PropTypes.element,
    label: PropTypes.string,
    to: PropTypes.string,
    href: PropTypes.string,
    reverse: PropTypes.bool,
  };

  render() {
    const { icon, label, to, href, reverse, ...others } = this.props;

    const Item = () => (
      <React.Fragment>
        {reverse && <Text css="flex: 1 1 auto; white-space: nowrap;">{label}</Text>}
        <Text color="primary" css="flex: 0 0 auto; width: 2rem; height: 2rem; display: flex; justify-content: center; align-items:center;">
          {icon}
        </Text>
        {!reverse && <Text css="flex: 1 1 auto; white-space: nowrap;">{label}</Text>}
      </React.Fragment>
    );

    if (to) {
      return (
        <Link to={to} {...others} css="gap: .5rem;">
          <Item />
        </Link>
      );
    }

    return (
      <LinkHref href={href} {...others} css="gap: .5rem;">
        <Item />
      </LinkHref>
    );
  }
}

export default Item;
