import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';

import { Box, Flex, Text } from 'rebass';
import { Col, Grid } from 'src/components/utilities/Grids';
import { Button } from 'src/components/elements/Button';
import AvatarList from 'src/components/blocks/AvatarList';
import FriendItem from 'src/components/blocks/FriendItem';
import Layout from 'src/layouts/Onboarding';
import Link from 'react-router-dom/Link';
import NavigationButton from 'src/components/elements/NavigationButton';
import { theme } from 'src/styles/theme';
import { AddIcon, ProfileIcon } from 'src/pages/Onboarding/Icons';

const AddFriendsButton = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  color: ${theme.colors.text};
  text-decoration: none;
`;

class Profile extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    onSubmit: PropTypes.func,
    removeFriend: PropTypes.func,
    selectAvatar: PropTypes.func,
    avatars: PropTypes.array,
    currentAvatar: PropTypes.string,
    hasFriends: PropTypes.bool,
    updateUser: PropTypes.func,
    userData: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const {
      t,
      onSubmit,
      removeFriend,
      selectAvatar,
      avatars,
      currentAvatar,
      hasFriends,
      updateUser,
      userData,
    } = this.props;

    return (
      <Layout
        title={t('onboarding.student.profile.title')}
        header={
          <Grid justifyContent="center" pt={[2, 2, 3]}>
            <Col width={[1 / 1, 1 / 1, 1 / 2]} justifyContent={['center', 'center', 'flex-end']} pb={[2, 2, 3]}>
              <NavigationButton type="button" valid number="1" label="Tes informations" />
            </Col>
            <Col width={[1 / 1, 1 / 1, 1 / 2]} justifyContent={['center', 'center', 'flex-start']} pb={[2, 2, 3]}>
              <NavigationButton type="button" current number="2" label={t('yourProfile')} />
            </Col>
          </Grid>
        }
        illustration={<ProfileIcon />}
      >
        <Flex py={3} alignItems="center" css="flex: 1 1 auto;">
          <Grid>
            {userData.score > 0 && (
              <Col width={1 / 1} pb={4}>
                <Text color="red">{t('resetHasBeenMade')}</Text>
              </Col>
            )}
            {avatars && (
              <Col width={1 / 1} pb={4}>
                <Box>
                  <Text
                    pb={1}
                    fontSize={['1.4rem', '1.4rem', '1.4rem', '1.2rem']}
                    fontWeight={['semibold', 'semibold', 'semibold', 'normal']}
                  >
                    {t('yourAvatar')}
                  </Text>
                  <AvatarList avatars={avatars} onAvatarClick={selectAvatar} selectedAvatar={currentAvatar} />
                </Box>
              </Col>
            )}
            <Col width={1 / 1} pb={4}>
              <Box width={1 / 1}>
                <Text
                  as="label"
                  pb={2}
                  fontSize={['1.4rem', '1.4rem', '1.4rem', '1.2rem']}
                  fontWeight={['semibold', 'semibold', 'semibold', 'normal']}
                >
                  {t('yourFriends')}
                </Text>
                <AddFriendsButton to={{ pathname: '/bienvenu/amis', state: { currentAvatar: currentAvatar } }}>
                  <AddIcon />
                  <Text as="span" ml={1} fontSize="1.6rem" fontWeight="semibold">
                    {t('addFriends')}
                  </Text>
                </AddFriendsButton>
                {hasFriends && (
                  <Grid py={1}>
                    {userData.friends.map(friend => (
                      <Col key={friend.id} width={[1 / 1, 1 / 2, 1 / 3, 1 / 2, 1 / 3]} pb={1}>
                        <FriendItem
                          friend={friend}
                          isAdded={true}
                          onRemoveFriend={removeFriend(userData, friend, updateUser)}
                        />
                      </Col>
                    ))}
                  </Grid>
                )}
              </Box>
            </Col>
            <Col width={1 / 1} justifyContent="center">
              <Button
                onClick={() => onSubmit(updateUser)}
                disabled={!currentAvatar}
                css="width: 100%; max-width: 22rem;"
              >
                {t('validate')}
              </Button>
            </Col>
          </Grid>
        </Flex>
      </Layout>
    );
  }
}

export default withNamespaces()(Profile);
