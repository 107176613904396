import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { adopt } from 'react-adopt';
import { COLLECTIONS_ORDER_BY, GET_COLLECTIONS } from 'src/queries/collections';
import { GET_FOLDERS } from 'src/queries/folders';
import { formatSchoolDegrees } from 'src/utils/degrees';
import Content from './Content';

class TeacherDashboardContainer extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
  };

  Composed = null;
  isContentLoaded = false;

  render() {
    const { user } = this.props;
    const userSchoolDegrees = user.schools.length > 0 ? formatSchoolDegrees(user.schools) : null;
    const subjects = user.schoolSubjects.map(subject => subject.id);

    /* eslint-disable */
    const queries = {
      authorCollections: ({ render }) => (
        <Query
          query={GET_COLLECTIONS}
          fetchPolicy="cache-and-network"
          variables={{
            limit: 2,
            filterBy: [{ property: 'author', where: user.id }],
            orderBy: [COLLECTIONS_ORDER_BY.newest],
          }}
        >
          {render}
        </Query>
      ),
      authorFolders: ({ render }) => (
        <Query
          query={GET_FOLDERS}
          fetchPolicy="network-only"
          variables={{
            limit: 2,
            filterBy: [{ property: 'author', where: user.id }],
          }}
        >
          {render}
        </Query>
      ),
    };

    if (userSchoolDegrees && subjects) {
      userSchoolDegrees.map(schoolDegree => {
        subjects.map(subject => {
          queries[`${schoolDegree.id}-${subject}`] = ({ render }) => (
            <Query
              query={GET_COLLECTIONS}
              variables={{
                limit: 3,
                filterBy: [
                  { join: 'schoolDegrees', property: 'id', where: schoolDegree.id },
                  { property: 'isOfficial', where: 'true' },
                  { join: 'schoolSubject', property: 'id', where: subject },
                ],
              }}
            >
              {render}
            </Query>
          );
        });
      });
    }

    if (!this.Composed) {
      this.Composed = adopt(queries);
    }
    /* eslint-enable */

    return (
      <this.Composed>
        {({ authorCollections, authorFolders, ...others }) => {
          let isLoading = authorCollections.loading || authorFolders.loading;
          const errors = authorCollections.error || authorFolders.error;

          if (!isLoading && userSchoolDegrees) {
            userSchoolDegrees.map(schoolDegree => {
              subjects.map(subject => {
                if (!isLoading) {
                  isLoading = others[`${schoolDegree.id}-${subject}`].loading;
                }
              });
            });
          }

          if (isLoading && !this.isContentLoaded) return <div />;
          if (errors) return <p>Error :(</p>;
          this.isContentLoaded = true;

          return (
            <Content
              collections={authorCollections.data.collections.results || []}
              folders={authorFolders.data.folders.results || []}
              user={user}
              officialCollections={userSchoolDegrees ? others : null}
            />
          );
        }}
      </this.Composed>
    );
  }
}

export default TeacherDashboardContainer;
