"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.graphql = void 0;
/* eslint-disable */
var types = require("./graphql");
/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
var documents = {
    "\n  fragment AvatarLevelParts on AvatarLevel {\n    id\n    name\n    score\n  }\n": types.AvatarLevelPartsFragmentDoc,
    "\n  query GetAvatarLevels($score: Int = null, $type: String!) {\n    avatarLevels(score: $score, type: $type) {\n      ...AvatarLevelParts\n      avatars {\n        ...AvatarParts\n      }\n    }\n  }\n  \n  \n": types.GetAvatarLevelsDocument,
    "\n  fragment AvatarParts on Avatar {\n    id\n    image\n    type\n  }\n": types.AvatarPartsFragmentDoc,
    "\n  query GetAvatars($type: String!) {\n    avatars(type: $type) {\n      ...AvatarParts\n    }\n  }\n  \n": types.GetAvatarsDocument,
    "\n  mutation uploadAvatar($file: Upload!) {\n    uploadAvatar(file: $file) {\n      ...AvatarParts\n    }\n  }\n  \n": types.UploadAvatarDocument,
    "\n  mutation removeAvatar($avatarId: ID!) {\n    removeAvatar(avatarId: $avatarId) {\n      ...AvatarParts\n    }\n  }\n  \n": types.RemoveAvatarDocument,
    "\n  fragment CardFragment on Card {\n    id\n    frontText\n    frontDescription\n    frontAudio\n    frontImage\n    backText\n    backDescription\n    backAudio\n    backImage\n  }\n": types.CardFragmentFragmentDoc,
    "\n  mutation setCardStatus($card: ID!, $status: String!) {\n    setCardStatus(card: $card, status: $status) {\n      id\n      userLinkCard {\n        id\n        status\n      }\n    }\n  }\n": types.SetCardStatusDocument,
    "\n  mutation addClassroom($input: AddClassroomInput) {\n    addClassroom(input: $input) {\n      id\n      name\n    }\n  }\n": types.AddClassroomDocument,
    "\n  query restrictedClassroom($id: ID!) {\n    classroom(id: $id) {\n      id\n      name\n    }\n  }\n": types.RestrictedClassroomDocument,
    "\n  mutation removeClassroom($classroom: ID!) {\n    removeClassroom(classroom: $classroom)\n  }\n": types.RemoveClassroomDocument,
    "\n  query classroom($id: ID!) {\n    classroom(id: $id) {\n      id\n      name\n      students {\n        id\n        firstname\n        username\n        avatar {\n          ...AvatarParts\n        }\n        userLinkCards {\n          card {\n            id\n          }\n          status\n        }\n      }\n    }\n  }\n": types.ClassroomDocument,
    "\n  query classrooms(\n    $page: Int = 1\n    $limit: Int\n    $filterBy: [FilterBy]\n    $orderBy: [OrderBy]\n  ) {\n    classrooms(limit: $limit, page: $page, filterBy: $filterBy, orderBy: $orderBy) {\n      count\n      results {\n        id\n        name\n        teacher {\n          firstname\n          lastname\n        }\n        students {\n          id\n          firstname\n          username\n          avatar {\n            ...AvatarParts\n          }\n        }\n      }\n    }\n  }\n": types.ClassroomsDocument,
    "\n  mutation joinClassroom($classroom: ID!) {\n    joinClassroom(classroom: $classroom)\n  }\n": types.JoinClassroomDocument,
    "\n  fragment CollectionParts on Collection {\n    id\n    serialNumber\n    title\n    description\n    isPublished\n    schoolSubject {\n      id\n      name\n      color\n    }\n    schoolDegrees {\n      id\n      name\n      schoolLevel {\n        id\n        settings {\n          brioTimerEasy\n          brioTimerMedium\n          brioTimerHard\n          memoTimerEasy\n          memoTimerMedium\n          memoTimerHard\n        }\n      }\n    }\n    isOfficial\n    countCards\n    author {\n      id\n      firstname\n      lastname\n      roles\n      avatar {\n        ...AvatarParts\n      }\n    }\n    updated\n    assoMode\n    memoMode\n    typoMode\n    brioMode\n    ignoreCase\n    ignoreOrder\n    ignoreSpaces\n    ignoreCharacters\n  }\n  \n": types.CollectionPartsFragmentDoc,
    "\n  fragment FavoriteParts on UserLinkCollection {\n    id\n    isFavorite\n  }\n": types.FavoritePartsFragmentDoc,
    "\n  mutation AddCollection($input: AddCollectionInput) {\n    AddCollection(input: $input) {\n      ...CollectionParts\n      cards {\n        ...CardFragment\n      }\n    }\n  }\n  \n  \n": types.AddCollectionDocument,
    "\n  query GetCollection($id: ID!, $withUserLinkCard: Boolean = false) {\n    collection(id: $id) {\n      ...CollectionParts\n      cards {\n        ...CardFragment\n        userLinkCard @include(if: $withUserLinkCard) {\n          id\n          user {\n            id\n            username\n          }\n          status\n          failed\n        }\n      }\n      linkUsers {\n        ...FavoriteParts\n        status\n        user {\n          id\n        }\n      }\n      folders {\n        id\n        title\n      }\n    }\n  }\n  \n  \n  \n": types.GetCollectionDocument,
    "\n  query GetCollections($page: Int = 1, $limit: Int, $filterBy: [FilterBy], $orderBy: [OrderBy], $withCards: Boolean = false) {\n    collections(page: $page, limit: $limit, filterBy: $filterBy, orderBy: $orderBy) {\n      count\n      results {\n        ...CollectionParts\n        cards @include(if: $withCards) {\n          ...CardFragment\n        }\n        linkUsers {\n          ...FavoriteParts\n          status\n          user {\n            id\n          }\n        }\n      }\n    }\n  }\n  \n  \n  \n": types.GetCollectionsDocument,
    "\n  query GetOfficialCollections($schoolDegree: ID!) {\n    officialCollections(schoolDegree: $schoolDegree) {\n      ...CollectionParts\n      linkUsers {\n        ...FavoriteParts\n        status\n        user {\n          id\n        }\n      }\n    }\n  }\n  \n  \n": types.GetOfficialCollectionsDocument,
    "\n  mutation toggleFavoriteCollection($collection: ID!) {\n    toggleFavoriteCollection(collection: $collection) {\n      id\n      linkUsers {\n        ...FavoriteParts\n        status\n        user {\n          id\n        }\n      }\n    }\n  }\n  \n": types.ToggleFavoriteCollectionDocument,
    "\n  mutation togglePublicationStatusCollection($collection: ID!) {\n    togglePublicationStatusCollection(collection: $collection) {\n      id\n      isPublished\n    }\n  }\n": types.TogglePublicationStatusCollectionDocument,
    "\n  mutation setCollectionSeen($collection: ID!) {\n    setCollectionSeen(collection: $collection) {\n      id\n    }\n  }\n": types.SetCollectionSeenDocument,
    "\n  mutation setCollectionStatus($collection: ID!, $status: String!) {\n    setCollectionStatus(collection: $collection, status: $status) {\n      id\n      linkUsers {\n        id\n        status\n      }\n    }\n  }\n": types.SetCollectionStatusDocument,
    "\n  mutation saveScore($score: Int!, $time: Int!, $errors: Int!, $game: String!, $collection: ID!, $duel: ID) {\n    saveScore(score: $score, time: $time, errors: $errors, game: $game, collection: $collection, duel: $duel) {\n      id\n    }\n  }\n": types.SaveScoreDocument,
    "\n  mutation finishGame($collection: ID!, $game: String!, $won: Boolean!, $score: Int!, $time: Int!) {\n    finishGame(collection: $collection, game: $game, won: $won, score: $score, time: $time)\n  }\n": types.FinishGameDocument,
    "\n  mutation startGame($collection: ID!, $game: String!) {\n    startGame(collection: $collection, game: $game)\n  }\n": types.StartGameDocument,
    "\n  mutation removeCollection($collection: ID!) {\n    removeCollection(collection: $collection)\n  }\n": types.RemoveCollectionDocument,
    "\n  fragment DuelParts on Duel {\n    id\n    opponent {\n      id\n      firstname\n      avatar {\n        ...AvatarParts\n      }\n    }\n    challenger {\n      id\n      firstname\n      avatar {\n        ...AvatarParts\n      }\n    }\n    difficulty\n    cardsOrder\n    game\n    scoreChallenger\n    scoreOpponent\n    timeChallenger\n    timeOpponent\n    answersChallenger\n    answersOpponent\n    errorsChallenger\n    errorsOpponent\n    collection {\n      ...CollectionParts\n    }\n    expiredAtInDays\n    created\n  }\n  \n  \n": types.DuelPartsFragmentDoc,
    "\n  mutation addDuel($input: AddDuelInput) {\n    addDuel(input: $input) {\n      ...DuelParts\n    }\n  }\n  \n": types.AddDuelDocument,
    "\n  query GetDuel($id: ID!) {\n    duel(id: $id) {\n      ...DuelParts\n    }\n  }\n  \n": types.GetDuelDocument,
    "\n  query GetDuels(\n    $page: Int = 1\n    $limit: Int\n    $old: Boolean\n    $orderBy: [OrderBy] = [{ property: \"created\", order: \"DESC\" }]\n  ) {\n    duels(page: $page, limit: $limit, old: $old, orderBy: $orderBy) {\n      count\n      results {\n        ...DuelParts\n      }\n    }\n  }\n  \n": types.GetDuelsDocument,
    "\n  mutation saveDuelScore($duel: ID, $answers: Int!, $score: Int!, $time: Int!, $errors: Int!) {\n    saveDuelScore(duel: $duel, answers: $answers, score: $score, time: $time, errors: $errors) {\n      id\n    }\n  }\n": types.SaveDuelScoreDocument,
    "\n  mutation generateDuels($input: AddDuelsClassroomInput) {\n    generateDuels(input: $input)\n  }\n": types.GenerateDuelsDocument,
    "\n  mutation SingleUpload($file: Upload!) {\n    SingleUpload(file: $file)\n  }\n": types.SingleUploadDocument,
    "\n  mutation RemoveFile($filename: String!, $cardId: ID!) {\n    RemoveFile(filename: $filename, cardId: $cardId)\n  }\n": types.RemoveFileDocument,
    "\n  fragment FolderParts on Folder {\n    id\n    title\n    description\n    isPublished\n    isOfficial\n    schoolSubject {\n      id\n      name\n      color\n    }\n    schoolDegrees {\n      id\n      name\n    }\n    countCollections\n    author {\n      id\n      firstname\n      lastname\n      roles\n    }\n  }\n": types.FolderPartsFragmentDoc,
    "\n  fragment SubscriptionPart on UserLinkFolder {\n    id\n  }\n": types.SubscriptionPartFragmentDoc,
    "\n  query GetFolder(\n    $id: ID!\n    $collectionLimit: Int\n    $collectionPage: Int = 1\n    $collectionsFilterBy: [FilterBy]\n    $collectionsOrderBy: [OrderBy] = [{ property: \"updated\", order: \"DESC\" }]\n    $withCollections: Boolean = true\n  ) {\n    folder(id: $id) {\n      ...FolderParts\n      linkUsers {\n        ...SubscriptionPart\n      }\n      collections(\n        limit: $collectionLimit\n        page: $collectionPage\n        filterBy: $collectionsFilterBy\n        orderBy: $collectionsOrderBy\n      ) @include(if: $withCollections) {\n        count\n        results {\n          ...CollectionParts\n          linkUsers {\n            ...FavoriteParts\n            status\n            user {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n  \n  \n  \n  \n": types.GetFolderDocument,
    "\n  query GetFolders(\n    $page: Int = 1\n    $limit: Int\n    $filterBy: [FilterBy]\n    $orderBy: [OrderBy]\n    $withCollections: Boolean = false\n    $collectionLimit: Int = 20\n    $collectionPage: Int = 1\n    $collectionsFilterBy: [FilterBy]\n    $collectionsOrderBy: [OrderBy] = [{ property: \"updated\", order: \"DESC\" }]\n  ) {\n    folders(limit: $limit, page: $page, filterBy: $filterBy, orderBy: $orderBy) {\n      count\n      results {\n        ...FolderParts\n        linkUsers {\n          ...SubscriptionPart\n        }\n        collections(\n          limit: $collectionLimit\n          page: $collectionPage\n          filterBy: $collectionsFilterBy\n          orderBy: $collectionsOrderBy\n        ) @include(if: $withCollections) {\n          count\n          results {\n            ...CollectionParts\n          }\n        }\n        countCollectionsUnseen\n      }\n    }\n  }\n  \n  \n  \n": types.GetFoldersDocument,
    "\n  query GetOfficialFolders($schoolDegree: ID!) {\n    officialFolders(schoolDegree: $schoolDegree) {\n      ...FolderParts\n      linkUsers {\n        ...SubscriptionPart\n      }\n    }\n  }\n  \n  \n": types.GetOfficialFoldersDocument,
    "\n  mutation toggleSubscriptionFolder($folder: ID!) {\n    toggleSubscriptionFolder(folder: $folder) {\n      id\n      linkUsers {\n        ...SubscriptionPart\n      }\n    }\n  }\n  \n": types.ToggleSubscriptionFolderDocument,
    "\n  mutation togglePublicationStatusFolder($folder: ID!) {\n    togglePublicationStatusFolder(folder: $folder) {\n      id\n      isPublished\n    }\n  }\n": types.TogglePublicationStatusFolderDocument,
    "\n  mutation AddFolder($input: AddFolderInput) {\n    AddFolder(input: $input) {\n      id\n      title\n    }\n  }\n": types.AddFolderDocument,
    "\n  mutation setCollectionsOfFolder($folder: ID!, $collections: [ID!]) {\n    setCollectionsOfFolder(folder: $folder, collections: $collections) {\n      id\n    }\n  }\n": types.SetCollectionsOfFolderDocument,
    "\n  mutation RemoveFolder($folder: ID!) {\n    RemoveFolder(folder: $folder)\n  }\n": types.RemoveFolderDocument,
    "\n  mutation addCollectionToFolders($collection: ID!, $folders: [ID!]) {\n    addCollectionToFolders(collection: $collection, folders: $folders) {\n      id\n      folders {\n        id\n        title\n      }\n    }\n  }\n": types.AddCollectionToFoldersDocument,
    "\n    mutation proposeCollection($collection: ID!, $classroom: ID!) {\n        proposeCollection(collection: $collection, classroom: $classroom)\n    }\n": types.ProposeCollectionDocument,
    "\n    mutation removeProposedCollection($collection: ID!, $teacher: ID!) {\n        removeProposedCollection(collection: $collection, teacher: $teacher)\n    }\n": types.RemoveProposedCollectionDocument,
    "\n    query GetProposedCollections(\n        $page: Int = 1\n        $limit: Int\n        $filterBy: [FilterBy]\n        $orderBy: [OrderBy]\n    ) {\n        proposedCollections(limit: $limit, page: $page, filterBy: $filterBy, orderBy: $orderBy) {\n            count\n            results {\n                id\n                collection {\n                    id\n                }\n                teacher {\n                    id\n                    firstname\n                    lastname\n                }\n            }\n        }\n    }\n": types.GetProposedCollectionsDocument,
    "\n    query collectionStats($collection: ID!, $classroom: ID, $student: ID) {\n        collectionStats(collection: $collection, classroom: $classroom, student: $student) {\n            user {\n                id\n            }\n            game\n            score\n            played\n            won\n            time\n            unfinished\n        }\n    }\n": types.CollectionStatsDocument,
    "\n  query GetUsers(\n    $page: Int = 1\n    $limit: Int\n    $filterBy: [FilterBy]\n    $orderBy: [OrderBy]\n    $onlyStudent: Boolean = false\n  ) {\n    users(page: $page, limit: $limit, filterBy: $filterBy, orderBy: $orderBy, onlyStudent: $onlyStudent) {\n      count\n      results {\n        id\n        firstname\n        lastname\n        username\n        avatar {\n          ...AvatarParts\n        }\n      }\n    }\n  }\n  \n": types.GetUsersDocument,
    "\n  query GetStudent($id: ID!, $withUserLinkCards: Boolean = false) {\n    student(id: $id) {\n      id\n      email\n      firstname\n      lastname\n      username\n      avatar {\n        ...AvatarParts\n      }\n      userLinkCards @include(if: $withUserLinkCards) {\n          card {\n            id\n          }\n          status\n          failed\n        }\n    }\n  }\n  \n": types.GetStudentDocument,
    "\n  query GetStudents($page: Int = 1, $limit: Int, $filterBy: [FilterBy], $orderBy: [OrderBy]) {\n    students(page: $page, limit: $limit, filterBy: $filterBy, orderBy: $orderBy) {\n      count\n      results {\n        id\n        email\n        firstname\n        lastname\n        username\n        avatar {\n          ...AvatarParts\n        }\n      }\n    }\n  }\n  \n": types.GetStudentsDocument,
    "\n  query GetCurrentUser(\n    $withFriends: Boolean = false\n    $withLinkCollections: Boolean = false\n    $withTeacherStatistics: Boolean = false\n    $withStudentStatistics: Boolean = false\n  ) {\n    me {\n      id\n      email\n      firstname\n      lastname\n      username\n      roles\n      score\n      allTimeScore\n      hasBeenReset\n      schools {\n        id\n        name\n        schoolLevel {\n          id\n          name\n          schoolDegrees {\n            id\n            name\n          }\n        }\n      }\n      schoolLevel {\n        id\n        name\n      }\n      schoolDegree {\n        id\n        name\n      }\n      linkCollections @include(if: $withLinkCollections) {\n        id\n        collection {\n          id\n        }\n        playedMemo\n        playedAsso\n        playedTypo\n        playedBrio\n        scoreMemo\n        scoreAsso\n        scoreTypo\n        scoreBrio\n        timeMemo\n        timeAsso\n        timeTypo\n        timeBrio\n      }\n      countCollectionsWithToReviewStatus\n      friends @include(if: $withFriends) {\n        id\n        firstname\n        username\n        avatar {\n          ...AvatarParts\n        }\n      }\n      schoolSubjects {\n        id\n        name\n      }\n      avatar {\n        ...AvatarParts\n      }\n      countCreatedCollections @include(if: $withTeacherStatistics)\n      countCreatedAndPublishedCollections @include(if: $withTeacherStatistics)\n      countPlayersForCollections @include(if: $withTeacherStatistics)\n      countPlayersIterationForCollections @include(if: $withTeacherStatistics)\n      countNewCollections @include(if: $withStudentStatistics)\n      countWaitingDuels\n      countDuels @include(if: $withStudentStatistics)\n      countWinningDuels @include(if: $withStudentStatistics)\n      countCollections @include(if: $withStudentStatistics)\n    }\n  }\n  \n": types.GetCurrentUserDocument,
    "\n  mutation updateUser($input: UpdateUserInput) {\n    updateUser(input: $input) {\n      id\n      hasBeenReset\n      schoolDegree {\n        id\n        name\n      }\n      schoolLevel {\n        id\n        name\n      }\n      schoolSubjects {\n        id\n        name\n      }\n      friends {\n        id\n        avatar {\n          ...AvatarParts\n        }\n      }\n      avatar {\n        ...AvatarParts\n      }\n    }\n  }\n  \n": types.UpdateUserDocument,
    "\n  query GetRankinks($collection: ID!, $game: String!) {\n    rankings(collection: $collection, game: $game) {\n      firstRank\n      users {\n        avatar {\n          id\n          image\n        }\n        id\n        firstname\n        lastname\n        score\n        linkCollections {\n          collection {\n            id\n          }\n          scoreAsso\n          scoreBrio\n          scoreMemo\n          scoreTypo\n          timeAsso\n          timeBrio\n          timeMemo\n          timeTypo\n        }\n      }\n    }\n  }\n": types.GetRankinksDocument,
    "\n  mutation RemoveUser {\n    removeUser\n  }\n": types.RemoveUserDocument,
    "\n  query GetCurrentUserWaitingDuels {\n    me {\n      id\n      countWaitingDuels\n    }\n  }\n": types.GetCurrentUserWaitingDuelsDocument,
};
function graphql(source) {
    var _a;
    return (_a = documents[source]) !== null && _a !== void 0 ? _a : {};
}
exports.graphql = graphql;
