import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { theme } from 'src/styles/theme';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass';
import { svgStyle } from 'src/styles/helpers';
import DeleteSvg from 'assets/svg/delete.svg';
import CloseSvg from 'assets/svg/close.svg';
import AddSvg from 'assets/svg/plus.svg';

export const DeleteIcon = styled(DeleteSvg)`
  ${svgStyle};
  width: 2.2rem;
  height: 2.2rem;
`;

export const CloseIcon = styled(CloseSvg)`
  ${svgStyle};
  width: 1.4rem;
  height: 1.4rem;
`;

export const AddIcon = styled(AddSvg)`
  ${svgStyle};
  width: 1.4rem;
  height: 1.4rem;
  transition: transform 225ms ease;
  ${props =>
    props.selected &&
    `
    transform: rotate(135deg);
  `};
`;

const Container = styled(Flex)`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  transition: opacity 225ms ease;
  border: 2px solid ${props => (props.selected ? theme.colors.secondary : 'transparent')};
`;

const Button = styled(Flex)`
  width: 4rem;
  height: 4rem;
  border: none;
  background-color: transparent;
  color: ${theme.colors.secondary};
  cursor: pointer;
  outline: 0;
  &:hover {
    color: ${theme.colors.secondaryLight};
  }
`;

class FriendItem extends PureComponent {
  static propTypes = {
    friend: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    isAdded: PropTypes.bool,
    onAddFriend: PropTypes.func,
    onRemoveFriend: PropTypes.func,
    selectedMode: PropTypes.bool,
  };

  static defaultProps = {
    selectedMode: false,
  };

  handleRemoveFriend = () => {
    this.props.onRemoveFriend();
  };

  handleAddFriend = () => {
    this.props.onAddFriend();
  };

  render() {
    const { friend, disabled, isAdded, selectedMode } = this.props;

    return (
      <Container disabled={disabled ? true : null} selected={selectedMode && isAdded} bg="white" alignItems="center">
        <Box css="width: 4.4rem;">
          {friend.avatar && (
            <img src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${friend.avatar.image}`} />
          )}
        </Box>
        <Text py=".8rem" pl=".8rem" pr="1.2rem" fontSize="1.2rem" css="flex: 1 1 auto; line-height: 1.4;">
          <Text fontWeight="semibold">{friend.firstname}</Text>
          <Text>{friend.username}</Text>
        </Text>
        <Flex alignItems="center" justifyContent="center" pr={1}>
          {isAdded ? (
            <Button
              as="button"
              type="button"
              alignItems="center"
              justifyContent="center"
              onClick={this.handleRemoveFriend}
            >
              {selectedMode ? <AddIcon selected={selectedMode} /> : <DeleteIcon />}
            </Button>
          ) : (
            <Button
              as="button"
              type="button"
              alignItems="center"
              justifyContent="center"
              onClick={this.handleAddFriend}
            >
              <AddIcon />
            </Button>
          )}
        </Flex>
      </Container>
    );
  }
}

export default FriendItem;
