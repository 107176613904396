import gql from 'graphql-tag';

export const CARD_FRAGMENT = gql`
  fragment CardFragment on Card {
    id
    frontText
    frontDescription
    frontAudio
    frontImage
    backText
    backDescription
    backAudio
    backImage
  }
`;

export const SET_CARD_STATUS = gql`
  mutation setCardStatus($card: ID!, $status: String!) {
    setCardStatus(card: $card, status: $status) {
      id
      userLinkCard {
        id
        status
      }
    }
  }
`;
