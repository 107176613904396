import styled from 'styled-components';

export default styled.hr`
  position: relative;
  left: ${props => (props.open ? 0 : '-2rem')};
  width: calc(100% - 4rem);
  margin-top: 0;
  border: 1px solid rgba(255, 255, 255, 0.14);
  transition: left 225ms ease;
`;
