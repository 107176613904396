import gql from 'graphql-tag';

export const GET_DEGREES = gql`
  {
    degrees {
      id
      name
      schoolLevel {
        id
      }
      schoolSubjects {
        id
      }
    }
  }
`;
