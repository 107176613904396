import { FORM_ERROR } from 'final-form';
import set from 'lodash.set';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { withNamespaces } from 'react-i18next';

export const handleSubmissionErrors = errors => {
  const { graphQLErrors } = errors;
  if (graphQLErrors && graphQLErrors.length > 0) {
    const formattedErrors = {
      [FORM_ERROR]: graphQLErrors[0].message,
    };

    if (graphQLErrors[0].errors) {
      Object.keys(graphQLErrors[0].errors).forEach(path => {
        set(formattedErrors, path, graphQLErrors[0].errors[path]);
      });
    }

    return formattedErrors;
  } else {
    console.error(errors);
    throw errors;
  }
};

class Error extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    t: PropTypes.func,
  };

  render() {
    let { name, t } = this.props;

    return (
      <Field
        name={name}
        subscription={{
          touched: true,
          error: true,
          submitError: true,
          data: true,
        }}
        render={({ meta }) => {
          const errors = meta.error || meta.submitError || (meta.data && meta.data.error);
          if (errors) {
            if (errors.constructor === String) {
              return <span style={{ color: 'red' }}>{t(errors)}</span>;
            } else if (errors.constructor === Array) {
              return errors.map((error, key) => (
                <span style={{ color: 'red' }} key={key}>
                  {t(error)}
                </span>
              ));
            }
          }

          return null;
        }}
      />
    );
  }
}

export const FieldError = withNamespaces()(Error);
