import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import translationFR from 'src/locales/fr/translation.json';
import translationEN from 'src/locales/en/translation.json';

// the translations
const resources = {
  fr: {
    translation: translationFR,
  },
  en: {
    translation: translationEN,
  },
};
i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage(),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export function getLanguage() {
  return localStorage.getItem('lang') || 'fr';
}

export function setLanguage(lang) {
  localStorage.setItem('lang', lang);
}

export default i18n;
