import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { Text } from 'rebass';
import { Content } from 'src/components/utilities/Containers';
import { LinkButton } from 'src/components/elements/Button';
import FolderList from 'src/components/blocks/FolderList';
import Title from 'src/components/typography/Title';

import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';
import SubscriptionSvg from 'assets/svg/chain.svg';

const SubscriptionIcon = styled(SubscriptionSvg)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

class AuthorFolders extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    user: PropTypes.object,
    folders: PropTypes.array,
  };

  render() {
    const { t, user, folders } = this.props;

    return (
      <Content mt={3}>
        <Title sm icon={<SubscriptionIcon />}>
          {t('myChains')}
        </Title>
        <FolderList
          data={folders}
          showBuildButton={true}
          editableCards={true}
          author={user.id}
          showSubscribeButton={false}
        />
        {folders.length > 0 && (
          <Text pt={1} textAlign="center">
            <LinkButton to="/mes-chaines" css="width: 100%; max-width: 22rem;">
              {t('seeAllMyChain')}
            </LinkButton>
          </Text>
        )}
      </Content>
    );
  }
}

export default withNamespaces()(AuthorFolders);
