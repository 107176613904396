import gql from 'graphql-tag';
import { AVATAR_FRAGMENT } from './avatars';

export const AVATAR_LEVEL_FRAGMENT = gql`
  fragment AvatarLevelParts on AvatarLevel {
    id
    name
    score
  }
`;

export const GET_AVATAR_LEVELS = gql`
  query GetAvatarLevels($score: Int = null, $type: String!) {
    avatarLevels(score: $score, type: $type) {
      ...AvatarLevelParts
      avatars {
        ...AvatarParts
      }
    }
  }
  ${AVATAR_LEVEL_FRAGMENT}
  ${AVATAR_FRAGMENT}
`;
