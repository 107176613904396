import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { Box, Text } from 'rebass';
import { Content } from 'src/components/utilities/Containers';
import { LinkButton } from 'src/components/elements/Button';
import CollectionList from 'src/components/blocks/CollectionList';
import Title from 'src/components/typography/Title';
import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';
import RecommandationsSvg from 'assets/svg/recommandations.svg';

const RecommandationsIcon = styled(RecommandationsSvg)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

class OfficialCollections extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    collections: PropTypes.object,
    userSchoolDegrees: PropTypes.array,
  };

  render() {
    const { t, collections, userSchoolDegrees } = this.props;

    return (
      <Content mt={4}>
        <Title sm icon={<RecommandationsIcon />}>
          {t('officialResource_plural')}
        </Title>
        {Object.keys(collections).map((key, index) => {
          if (
            collections[key].data &&
            collections[key].data.collections &&
            collections[key].data.collections.results.length > 0
          ) {
            const results = collections[key].data.collections.results;

            const degree = key.split('-')[0];
            const degreeObject = userSchoolDegrees.find((degreeObject) => degreeObject.id === degree);

            return (
              <Box key={index} pt={2}>
                <Text fontSize="1.8rem" fontWeight="semibold">
                  {degreeObject.name} - {results[0].schoolSubject.name}
                </Text>
                <CollectionList data={results} marginTopContainer={1} />
                {results.length > 2 && (
                  <Text pt={1} textAlign="center">
                    <Box pb={2}>
                      <LinkButton
                        to={`/ressources-officielles?subject=${results[0].schoolSubject.id}&degree=${
                          degree
                        }`}
                      >
                        {t('seeAllResources')}
                      </LinkButton>
                    </Box>
                  </Text>
                )}
              </Box>
            );
          }
        })}
      </Content>
    );
  }
}

export default withNamespaces()(OfficialCollections);
