import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import { Box } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import { Content } from 'src/components/utilities/Containers';
import Counter from './Counter';
import LinkRouter from 'react-router-dom/Link';
import { RetryIcon, DuelIcon, ChainIcon, BadgeIcon } from './Icons';
import { getNextScoreLevel } from 'src/utils/score';

const Link = styled(LinkRouter)`
  display: flex;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
`;

const UserWelcome = ({ t, user }) => {
  const nextScoreLevel = getNextScoreLevel(user.score);
  return (
    <Box mt={2} py={[2, 2, 3]}>
      <Content>
        <Grid justifyContent="center">
          <Col width={[6 / 12, 6 / 12, 3 / 12]} alignItems="center" justifyContent="center" pb={[2, 2, 0]}>
            <Link to="/series/a-revoir">
              <Counter
                icon={<RetryIcon />}
                label={t('toReviewSerieWithCount', { count: user.countCollectionsWithToReviewStatus })}
              />
            </Link>
          </Col>
          <Col width={[6 / 12, 6 / 12, 3 / 12]} alignItems="center" justifyContent="center" pb={[2, 2, 0]}>
            <Link to="/mes-duels">
              <Counter icon={<DuelIcon />} label={t('pendingDuelWithCount', { count: user.countWaitingDuels })} />
            </Link>
          </Col>
          {user.countNewCollections !== 0 && (
            <Col width={[6 / 12, 6 / 12, 3 / 12]} alignItems="center" justifyContent="center">
              <Link to="/chaines/abonnements">
                <Counter
                  icon={<ChainIcon />}
                  label={t('newSerieInMySubscriptionsWithCount', { count: user.countNewCollections })}
                />
              </Link>
            </Col>
          )}
          {nextScoreLevel !== 0 && (
            <Col width={[6 / 12, 6 / 12, 3 / 12]} alignItems="center" justifyContent="center">
              <Link to="/mes-recompenses">
                <Counter icon={<BadgeIcon />} label={t('pointsBeforeNextBadgeWithCount', { count: nextScoreLevel })} />
              </Link>
            </Col>
          )}
        </Grid>
      </Content>
    </Box>
  );
};

UserWelcome.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
};

export default withNamespaces()(UserWelcome);
