import React from 'react';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass';
import { Container } from 'src/components/utilities/Containers';
import { breakpoints, theme } from 'src/styles/theme';
import { LogoIcon } from './Icons';
import { HelpFooterIcon } from './Icons';

const LinkStyled = styled(Text)`
  text-decoration: none;
  &:hover {
    color: ${theme.colors.secondary};
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.6rem;
  }
`;

const FooterStyled = styled(Text)`
  height: 5.2rem;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px 0 rgba(234, 234, 234, 0.5);

  @media (min-width: ${breakpoints.md}) {
    height: 7.4rem;
  }
`;

const Footer = () => (
  <FooterStyled as="footer" lineHeight="1" className="screen">
    <Container css="height: 100%;">
      <Flex alignItems="center" justifyContent="space-between" css="height: 100%;">
        <LogoIcon />
        <Box>
          <LinkStyled as="a" fontSize="1.2rem" color="text" href="https://edu.ge.ch/qr/cortex-aide">
            <HelpFooterIcon />
            Aide
          </LinkStyled>
        </Box>
      </Flex>
    </Container>
  </FooterStyled>
);

export default Footer;
