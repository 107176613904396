import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { NEUTRAL_STATUS } from 'src/components/elements/Status';
import { UserContext } from 'src/context';
import { theme } from 'src/styles/theme';
import styled from 'styled-components';
import { CollectionAuthorActions, FolderAuthorActions } from './AuthorActions';
import Content from './Content';
import { CollectionFooter, FolderFooter } from './Footers';
import { CollectionHeader, FolderHeader } from './Headers';

const Card = styled.section`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.4rem;
  box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);
  ${props => props.css};
`;

const Collection = styled(Card)`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    height: 0.6rem;
    background-color: ${props => props.color || theme.colors['others']};
  }
`;

const Folder = styled(Card)`
  background-color: ${theme.colors.gray100};
  &:before {
    content: '';
    position: absolute;
    top: -2.4rem;
    left: -2.4rem;
    display: block;
    width: 4rem;
    height: 4rem;
    background-color: ${props => props.color || theme.colors['others']};
    transform: rotate(-45deg);
  }
`;

class CollectionCard extends PureComponent {
  static contextType = UserContext;
  static propTypes = {
    t: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    author: PropTypes.object,
    isOfficial: PropTypes.bool,
    isPublished: PropTypes.bool,
    schoolSubject: PropTypes.object.isRequired,
    schoolDegrees: PropTypes.array.isRequired,
    countCards: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    linkUsers: PropTypes.array,
    editable: PropTypes.bool,
    toggleCollectionInFolder: PropTypes.func,
    inFolder: PropTypes.bool,
    stateRedirect: PropTypes.object,
  };

  render() {
    const {
      t,
      id,
      title,
      description,
      author,
      isOfficial,
      isPublished,
      schoolSubject,
      schoolDegrees,
      countCards,
      linkUsers,
      editable,
      toggleCollectionInFolder,
      inFolder,
      stateRedirect
    } = this.props;

    return (
      <Collection color={schoolSubject.color}>
        <Content
          to={`/series/${id}`}
          state={stateRedirect}
          title={title}
          description={description}
          author={isOfficial ? t('officialResource') : t('byAuthor', { val: `${author.firstname} ${author.lastname}` })}
          header={<CollectionHeader schoolDegrees={schoolDegrees} schoolSubject={schoolSubject.name} />}
          toggleCollectionInFolder={!!toggleCollectionInFolder}
        />
        <CollectionFooter
          id={id}
          userStatus={linkUsers[0] ? linkUsers[0].status : NEUTRAL_STATUS}
          count={countCards}
          showFavoriteButton={true}
          isFavorite={linkUsers[0] ? linkUsers[0].isFavorite : false}
          toggleCollectionInFolder={toggleCollectionInFolder}
          inFolder={inFolder}
        />
        {editable && <CollectionAuthorActions id={id} isPublished={isPublished} />}
      </Collection>
    );
  }
}

class FolderCard extends PureComponent {
  static contextType = UserContext;
  static propTypes = {
    t: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    author: PropTypes.object,
    isOfficial: PropTypes.bool,
    isPublished: PropTypes.bool,
    schoolSubject: PropTypes.object.isRequired,
    countCollections: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    linkUsers: PropTypes.array,
    editable: PropTypes.bool,
    showSubscribeButton: PropTypes.bool,
    css: PropTypes.string,
    countCollectionsUnseen: PropTypes.number,
    contentBg: PropTypes.string,
    footerBg: PropTypes.string,
  };

  static defaultProps = {
    showSubscribeButton: true,
  };

  render() {
    const {
      t,
      id,
      title,
      author,
      isOfficial,
      isPublished,
      schoolSubject,
      countCollections,
      countCollectionsUnseen,
      linkUsers,
      editable,
      showSubscribeButton,
      css,
      contentBg,
      footerBg,
    } = this.props;
    const user = this.context;

    return (
      <Folder color={schoolSubject.color} css={css}>
        <Content
          to={`/chaines/${id}`}
          title={title}
          author={isOfficial ? t('officialResource') : t('byAuthor', { val: `${author.firstname} ${author.lastname}` })}
          header={
            <FolderHeader
              color={schoolSubject.color}
              label={schoolSubject.name}
              notification={countCollectionsUnseen}
            />
          }
          bg={contentBg}
        />
        <FolderFooter
          id={id}
          isOwner={user.id === author.id}
          count={countCollections}
          showSubscribeButton={showSubscribeButton}
          isSubscriber={linkUsers.length > 0 ? true : false}
          bg={footerBg}
        />
        {(editable || user.id === author.id) && <FolderAuthorActions id={id} isPublished={isPublished} />}
      </Folder>
    );
  }
}

Card.Collection = withNamespaces()(CollectionCard);
Card.Folder = withNamespaces()(FolderCard);
Card.Content = Content;
Card.CollectionHeader = CollectionHeader;
Card.CollectionFooter = CollectionFooter;
Card.CollectionAuthorActions = CollectionAuthorActions;
Card.FolderHeader = FolderHeader;
Card.FolderFooter = FolderFooter;
Card.FolderAuthorActions = FolderAuthorActions;

export default Card;
