import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Text } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import FavoriteButton from 'src/components/blocks/Card/FavoriteButton';
import { Status, TO_REVIEW_STATUS, LEARNED_STATUS, NEUTRAL_STATUS } from 'src/components/elements/Status';
import SubscribeButton from 'src/components/elements/SubscribeButton';
import { breakpoints, theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import CheckSvg from 'assets/svg/check.svg';
import PlusSvg from 'assets/svg/plus.svg';

const CheckIcon = styled(CheckSvg)`
  ${svgStyle};
  width: 1.4rem;
  height: 1rem;
`;

const PlusIcon = styled(PlusSvg)`
  ${svgStyle};
  width: 1rem;
  height: 1rem;
`;

const Container = styled(Grid)`
  min-height: 4rem;
  @media (min-width: ${breakpoints.md}) {
    min-height: 3rem;
  }
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 12.4rem;
  min-height: 3rem;
  padding: 0.4em 1.71429em;
  border: none;
  background-color: ${props => (props.inFolder ? theme.colors.primary : theme.colors.secondary)};
  color: ${theme.colors.white};
  font-family: ${theme.fonts.base};
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: ${theme.fontWeights.semibold};
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  transition: background-color ease 0.2s, color ease 0.2s;

  &:hover {
    background-color: ${props => (props.inFolder ? theme.colors.primaryDark : theme.colors.secondaryDark)};
  }
`;

const Code = ({ id, prefix }) => (
  <Box pl="1.2rem">
    <Text fontSize="1.2rem" fontWeight="bold">
      {prefix}
      {id}
    </Text>
  </Box>
);

Code.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefix: PropTypes.string,
};

const Count = ({ label }) => (
  <Text color="#636363" fontSize="1.2rem" textAlign="center" width="100%">
    {label}
  </Text>
);

Count.propTypes = {
  label: PropTypes.string.isRequired,
};

class Collection extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userStatus: PropTypes.oneOf([TO_REVIEW_STATUS, LEARNED_STATUS, NEUTRAL_STATUS]),
    count: PropTypes.number,
    showFavoriteButton: PropTypes.bool,
    isFavorite: PropTypes.bool,
    toggleCollectionInFolder: PropTypes.func,
    inFolder: PropTypes.bool,
  };

  static defaultProps = {
    count: 0,
  };

  render() {
    const { t, id, userStatus, count, showFavoriteButton, isFavorite, toggleCollectionInFolder, inFolder } = this.props;
    const renderCode = () => <Code prefix="s" id={id} />;
    const renderCount = () => <Count label={t('cardWithCount', { count })} />;

    if (showFavoriteButton && !toggleCollectionInFolder) {
      return (
        <Container bg="gray200">
          <Col width={[4 / 10, 4 / 10, 1 / 3]} alignItems="center">
            <Grid>
              <Col alignItems="center">{renderCode()}</Col>
              {userStatus !== NEUTRAL_STATUS && (
                <Col alignItems="center">
                  <Status status={userStatus} />
                </Col>
              )}
            </Grid>
          </Col>
          <Col width={[3 / 10, 3 / 10, 1 / 3]} alignItems="center">
            {renderCount()}
          </Col>
          <Col width={[3 / 10, 3 / 10, 1 / 3]} alignItems="center" justifyContent="flex-end">
            <FavoriteButton collection={id} isFavorite={isFavorite} />
          </Col>
        </Container>
      );
    }

    return (
      <Container bg="gray200">
        <Col alignItems="center">
          <Grid>
            <Col>{renderCode()}</Col>
            {userStatus !== NEUTRAL_STATUS && (
              <Col alignItems="center">
                <Status status={userStatus} />
              </Col>
            )}
          </Grid>
        </Col>
        <Col alignItems="center">{renderCount()}</Col>
        {toggleCollectionInFolder && (
          <Col alignItems="center" justifyContent="flex-end">
            <Button
              inFolder={inFolder ? 'true' : null}
              onClick={e => {
                e.preventDefault();
                toggleCollectionInFolder(id);
              }}
            >
              {inFolder ? (
                <React.Fragment>
                  <Flex as="span" width="1.2rem" alignItems="center" mr=".6rem">
                    <CheckIcon />
                  </Flex>
                  <Box as="span">{t('added', { context: 'female' })}</Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Flex as="span" width="1.2rem" alignItems="center" mr=".6rem">
                    <PlusIcon />
                  </Flex>
                  <Box as="span">{t('add')}</Box>
                </React.Fragment>
              )}
            </Button>
          </Col>
        )}
      </Container>
    );
  }
}

export const CollectionFooter = withNamespaces()(Collection);

class Folder extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    count: PropTypes.number,
    showSubscribeButton: PropTypes.bool,
    isSubscriber: PropTypes.bool,
    bg: PropTypes.string,
  };

  static defaultProps = {
    bg: 'gray200',
  };

  render() {
    const { t, id, count, showSubscribeButton, isSubscriber, bg } = this.props;
    const renderCode = () => <Code prefix="c" id={id} />;
    const renderCount = () => <Count label={t('serieWithCount', { count })} />;

    if (showSubscribeButton) {
      return (
        <Container bg={bg}>
          <Col width={1 / 3} alignItems="center">
            {renderCode()}
          </Col>
          <Col width={1 / 3} alignItems="center">
            {renderCount()}
          </Col>
          <Col width={1 / 3} alignItems="center" justifyContent="flex-end">
            <SubscribeButton folder={id} isSubscriber={isSubscriber} />
          </Col>
        </Container>
      );
    }

    return (
      <Container bg={bg}>
        <Col alignItems="center" flex="0 0 auto">
          {renderCode()}
        </Col>
        <Col alignItems="center" flex="0 0 auto" pr="1.2rem">
          {renderCount()}
        </Col>
      </Container>
    );
  }
}

export const FolderFooter = withNamespaces()(Folder);
