import styled from 'styled-components';
import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';

import Logo from 'assets/svg/logo.svg';

import HelpFooter from 'assets/svg/help-footer.svg';

export const LogoIcon = styled(Logo)`
  ${svgStyle};
  height: 2.5rem;

  @media (min-width: ${breakpoints.md}) {
    height: 3.2rem;
  }
`;

export const HelpFooterIcon = styled(HelpFooter)`
  ${svgStyle};
  height: 1.8rem;
  margin-right: 1rem;

  @media (min-width: ${breakpoints.md}) {
    height: 2.4rem;
    margin-right: 1rem;
  }
`;
