export const SCORE_BRONZE = 50;
export const SCORE_SILVER = 350;
export const SCORE_GOLD = 700;

export const WINNING_DUELS_BRONZE = 5;
export const WINNING_DUELS_SILVER = 25;
export const WINNING_DUELS_GOLD = 50;

export function getNextScoreLevel(userScore) {
  const nextScoreLevel = [SCORE_BRONZE, SCORE_SILVER, SCORE_GOLD].find(scoreLevel => scoreLevel > userScore);
  return nextScoreLevel ? nextScoreLevel - userScore : 0;
}
