import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Mutation, Query } from '@apollo/client/react/components';
import styled from 'styled-components';
import { TOGGLE_SUBSCRIPTION_FOLDER, GET_FOLDERS } from 'src/queries/folders';
import { GET_CURRENT_USER } from 'src/queries/users';
import { FOLDERS_LIMIT } from 'src/pages/StudentDashboard';
import { theme } from 'src/styles/theme';
import { QueryContext } from 'src/context';

const Button = styled.button`
  width: 100%;
  max-width: 92rem;
  height: 100%;
  background-color: transparent;
  border: none;
  color: #636363;
  font-size: 1.2rem;
  text-align: right;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.secondary};
  }
`;

class SubscriptionButton extends PureComponent {
  static contextType = QueryContext;
  render() {
    const { t, folder, isSubscriber } = this.props;
    return (
      <Query query={GET_CURRENT_USER}>
        {({ loading, data }) => {
          if (loading) return <div />;
          return (
            <Mutation
              mutation={TOGGLE_SUBSCRIPTION_FOLDER}
              refetchQueries={[
                {
                  query: GET_FOLDERS,
                  fetchPolicy: 'network-only',
                  variables: {
                    limit: FOLDERS_LIMIT,
                    filterBy: [{ join: 'linkUsers', property: 'user', where: data.me.id }],
                  },
                },
              ]}
              update={() => this.context && this.context.folders()}
            >
              {toggleSubscriptionFolder => (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    toggleSubscriptionFolder({ variables: { folder: folder } });
                  }}
                >
                  {isSubscriber ? t('unsubscribe') : t('subscribe')}
                </Button>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

SubscriptionButton.propTypes = {
  t: PropTypes.func,
  isSubscriber: PropTypes.bool,
  folder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default withNamespaces()(SubscriptionButton);
