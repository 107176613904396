import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import CorrectSvg from 'assets/svg/correct.svg';
import RetrySvg from 'assets/svg/retry.svg';

export const TO_REVIEW_STATUS = 'TO_REVIEW';
export const LEARNED_STATUS = 'LEARNED';
export const NEUTRAL_STATUS = 'NEUTRAL';

const getSize = ({ sm, md }) => {
  if (sm) return `height: 2.8rem;`;

  if (md) return `height: 3.8rem;`;

  return `height: 2rem;`;
};

const CorrectIcon = styled(CorrectSvg)`
  ${svgStyle};
  fill: ${theme.colors.secondary};
  ${props => getSize(props)};
`;

const RetryIcon = styled(RetrySvg)`
  ${svgStyle};
  fill: ${theme.colors.retry};
  ${props => getSize(props)};
`;

export class Status extends PureComponent {
  static propTypes = {
    status: PropTypes.oneOf([TO_REVIEW_STATUS, LEARNED_STATUS]).isRequired,
  };

  render() {
    const { status, ...others } = this.props;

    if (status === TO_REVIEW_STATUS) {
      return <RetryIcon {...others} />;
    }

    return <CorrectIcon {...others} />;
  }
}
