import styled from 'styled-components';

import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';

import Account from 'assets/svg/account.svg';
import Logo from 'assets/svg/logo.svg';
import Search from 'assets/svg/search.svg';
import Trophy from 'assets/svg/trophy.svg';

export const AccountIcon = styled(Account)`
  ${svgStyle};
  height: 3.4rem;
  margin-right: 1.2rem;
`;

export const LogoIcon = styled(Logo)`
  ${svgStyle};
  height: 2.5rem;
  margin-left: 1.4rem;

  @media (min-width: ${breakpoints.md}) {
    height: 5rem;
    margin-left: 2.4rem;
  }
`;

export const SearchIcon = styled(Search)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    width: 3rem;
    height: 3.2rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 3.5rem;
  }
`;

export const TrophyIcon = styled(Trophy)`
  ${svgStyle};
  height: 1.6rem;
  fill: #5f63bb;
  @media (min-width: ${breakpoints.md}) {
    height: 2rem;
  }
`;
