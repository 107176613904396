import loadable from '@loadable/component';
import React from 'react';
import SimpleLayout from 'src/SimpleLayout';
import { theme } from 'src/styles/theme';

const AddCollectionToFolder = loadable(() =>
  import(/* webpackChunkName: 'subscriptions' */ './../pages/AddCollectionToFolder')
);
const AddFriends = loadable(() => import(/* webpackChunkName: 'addFriends' */ './../pages/Onboarding/AddFriends'));
const Asso = loadable(() => import(/* webpackChunkName: 'asso' */ './../pages/Asso'));
const AuthorCollections = loadable(() =>
  import(/* webpackChunkName: 'authorCollections' */ './../pages/AuthorCollections')
);
const AuthorFolders = loadable(() => import(/* webpackChunkName: 'authorFolders' */ './../pages/AuthorFolders'));
const AuthorClassrooms = loadable(() => import(/* webpackChunkName: 'authorClassrooms' */ './../pages/AuthorClassrooms'));
const Brio = loadable(() => import(/* webpackChunkName: 'brio' */ './../pages/Brio'));
const Collection = loadable(() => import(/* webpackChunkName: 'collection' */ './../pages/Collection'));
const CollectionRecommendations = loadable(() =>
  import(/* webpackChunkName: 'collectionsRecommendations' */ './../pages/CollectionRecommendations')
);
const CollectionsToReview = loadable(() =>
  import(/* webpackChunkName: 'collectionsToReview' */ './../pages/CollectionsToReview')
);
const CreateCollection = loadable(() =>
  import(/* webpackChunkName: 'createCollection' */ './../pages/CreateCollection')
);
const CreateClassroom = loadable(() => import(/* webpackChunkName: 'createClassroom' */ './../pages/CreateClassroom'));
const JoinClassroom = loadable(() => import(/* webpackChunkName: 'createClassroom' */ './../pages/JoinClassroom'));
const CollectionStats = loadable(() => import(/* webpackChunkName: 'CollectionStats' */ './../pages/CollectionStats'));
const ClassroomStats = loadable(() => import(/* webpackChunkName: 'ClassroomStats' */ './../pages/ClassroomStats'));
const CreateFolder = loadable(() => import(/* webpackChunkName: 'createFolder' */ './../pages/CreateFolder'));
const Duels = loadable(() => import(/* webpackChunkName: 'duels' */ './../pages/Duels'));
const DuelProvoke = loadable(() => import(/* webpackChunkName: 'provoke' */ './../pages/DuelProvoke'));
const Favorites = loadable(() => import(/* webpackChunkName: 'favorites' */ './../pages/Favorites'));
const Folder = loadable(() => import(/* webpackChunkName: 'folder' */ './../pages/Folder'));
const FolderRecommendations = loadable(() =>
  import(/* webpackChunkName: 'folderRecommendations' */ './../pages/FolderRecommendations')
);
const Memo = loadable(() => import(/* webpackChunkName: 'asso' */ './../pages/Memo'));
const OfficialResources = loadable(() =>
  import(/* webpackChunkName: 'officialResources' */ './../pages/OfficialResources')
);
const GameResult = loadable(() => import(/* webpackChunkName: 'playResults' */ './../pages/GameResult'));
const Recommendations = loadable(() => import(/* webpackChunkName: 'recommendations' */ './../pages/Recommendations'));
const Rewards = loadable(() => import(/* webpackChunkName: 'rewards' */ './../pages/Rewards'));
const Settings = loadable(() => import(/* webpackChunkName: 'settings' */ './../pages/Settings'));
const Statistics = loadable(() => import(/* webpackChunkName: 'statistics' */ './../pages/Statistics'));
const Subscriptions = loadable(() => import(/* webpackChunkName: 'subscriptions' */ './../pages/Subscriptions'));
const Search = loadable(() => import(/* webpackChunkName: 'search' */ './../pages/Search'));
const Typo = loadable(() => import(/* webpackChunkName: 'typo' */ './../pages/Typo'));
const Errors = loadable(() => import(/* webpackChunkName: 'typo' */ './../pages/Errors'));
const MergeCollection = loadable(() => import(/* webpackChunkName: 'mergeCollection' */ './../pages/MergeCollection'));
const SplitCollection = loadable(() => import(/* webpackChunkName: 'splitCollection' */ './../pages/SplitCollection'));
const CreateDuelClassroom = loadable(() => import(/* webpackChunkName: 'createClassroom' */ './../pages/CreateDuelClassroom'));

export default {
  recommendations: {
    path: '/mes-recommandations',
    component: Recommendations,
    title: 'myRecommendations',
  },
  authorCollections: {
    path: '/mes-series',
    component: AuthorCollections,
    onlyForTeacher: true,
    title: 'mySeries',
  },
  authorFolders: {
    path: '/mes-chaines',
    component: AuthorFolders,
    onlyForTeacher: true,
    title: 'myChains',
  },
  authorClassrooms: {
    path: '/mes-classes',
    component: AuthorClassrooms,
    onlyForTeacher: true,
    title: 'myClassrooms',
  },
  favorites: {
    path: '/mes-favoris',
    component: Favorites,
    title: 'myFavorites',
  },
  duels: {
    path: '/mes-duels',
    component: Duels,
    title: 'myDuels',
  },
  collectionRecommendations: {
    path: '/series/recommandees/:schoolSubject',
    component: CollectionRecommendations,
    title: 'recommendations',
  },
  collectionsToReview: {
    path: '/series/a-revoir',
    component: CollectionsToReview,
    onlyForStudent: true,
    title: 'toReviewSerie',
  },
  createCollection: {
    path: '/series/creer',
    component: CreateCollection,
    onlyForTeacher: true,
    title: 'createASerie',
  },
  editCollection: {
    path: '/series/:id/editer',
    component: (props) => <CreateCollection {...props} key={props.match.params.id} />,
    onlyForTeacher: true,
    title: 'editASerie',
  },
  createClassroom: {
    path: '/classes/creer',
    component: CreateClassroom,
    onlyForTeacher: true,
    title: 'createAClassroom',
  },
  editClassroom: {
    path: '/classes/:id/editer',
    component: CreateClassroom,
    onlyForTeacher: true,
    title: 'editAClassroom',
  },
  addStudentsToClassroom: {
    path: '/classes/:id/ajouter-eleves',
    searchOnlyStudent: true,
    onlyForTeacher: true,
    component: CreateClassroom,
    title: 'addStudentsToClassroom',
  },
  joinClassroom: {
    path: '/classes/:id/rejoindre',
    onlyForStudent: true,
    component: JoinClassroom,
    title: 'joinAClassroom',
  },
  asso: {
    path: '/series/:id/association/:difficulty?',
    component: Asso,
    title: 'game.asso',
  },
  brio: {
    path: '/series/:id/brio/:difficulty?',
    component: Brio,
    title: 'game.brio',
  },
  memo: {
    path: '/series/:id/memorisation/:difficulty?',
    component: Memo,
    title: 'game.memo',
  },
  officialResources: {
    path: '/ressources-officielles',
    component: OfficialResources,
    title: 'officialResource_plural',
  },
  typo: {
    path: '/series/:id/typographie/:difficulty?',
    component: Typo,
    title: 'game.typo',
  },
  playResults: {
    path: '/series/:id/resultats',
    component: GameResult,
    title: 'result',
  },
  provoke: {
    path: '/series/:id/provoke',
    component: DuelProvoke,
    title: 'provokeADuel',
  },
  classroomStats: {
    path: '/series/:collectionId/classroom/:classroomId/stats',
    component: ClassroomStats,
    title: 'classroomStats',
  },
  collectionStats: {
    path: '/series/:id/stats',
    component: CollectionStats,
    onlyForStudent: true,
    title: 'collectionStats',
  },
  duelClassroom: {
    path: '/series/:id/duel',
    component: CreateDuelClassroom,
    title: 'provokeADuel',
  },
  mergeCollection: {
    path: '/series/:id/merge',
    component: MergeCollection,
    onlyForTeacher: true,
    title: 'mergeASerie',
  },
  splitCollection: {
    path: '/series/:id/split',
    component: SplitCollection,
    onlyForTeacher: true,
    title: 'splitASerie',
  },
  studentStats: {
    path: '/series/:id/student/:studentId/stats',
    component: CollectionStats,
    onlyForTeacher: true,
    title: 'studentStats',
  },
  collection: {
    path: '/series/:id',
    component: Collection,
    title: 'serie',
  },
  subscriptions: {
    path: '/chaines/abonnements',
    component: Subscriptions,
    title: 'subscriptions',
  },
  folderRecommendations: {
    path: '/chaines/recommandees/:schoolSubject',
    component: FolderRecommendations,
    title: 'chainRecommendations',
  },
  createFolder: {
    path: '/chaines/creer',
    component: CreateFolder,
    onlyForTeacher: true,
    title: 'createAChain',
  },
  addCollectionToFolder: {
    path: '/chaines/:id/editer-series',
    component: AddCollectionToFolder,
    onlyForTeacher: true,
    title: 'editTheChain',
  },
  editFolder: {
    path: '/chaines/:id/editer',
    component: CreateFolder,
    onlyForTeacher: true,
    title: 'editTheChain',
  },
  folder: {
    path: '/chaines/:id',
    component: Folder,
    backgroundColor: theme.colors.gray100,
    title: 'chain',
  },
  search: {
    path: '/recherche/:search?',
    component: Search,
    title: 'search',
  },
  addFriendsOnboarding: {
    path: '/bienvenu/amis',
    layout: SimpleLayout,
    showBackToProfileButton: true,
    searchOnlyStudent: true,
    component: AddFriends,
    showOnboarding: false,
    title: 'inviteFriends',
  },
  settings: {
    path: '/mes-parametres',
    component: Settings,
    title: 'settings',
  },
  statistics: {
    path: '/mes-statistiques',
    component: Statistics,
    title: 'myStatistics',
  },
  rewards: {
    path: '/mes-recompenses',
    component: Rewards,
    onlyForStudent: true,
    title: 'myRewards',
  },
  addFriends: {
    path: '/mes-contacts',
    searchOnlyStudent: true,
    component: AddFriends,
    title: 'inviteFriends',
  },
  errors: {
    path: '/erreur',
    component: Errors,
    title: 'error',
  },
};
