import React, { Component } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { svgStyle, visuallyHidden } from 'src/styles/helpers';
import { GET_AVATARS, UPLOAD_AVATAR } from 'src/queries/avatars';
import PlusSvg from 'assets/svg/plus.svg';

const Button = styled(Flex)`
  border-radius: 50%;
  width: 9.2rem;
  height: 9.2rem;
  cursor: pointer;
`;

const PlusIcon = styled(PlusSvg)`
  ${svgStyle};
  width: 3.4rem;
  height: 3.4rem;
  pointer-events: none;
`;

const VisuallyHiddenInput = styled.input`
  ${visuallyHidden};
`;

class UploadAvatar extends Component {
  upload = (uploadMutation) => ({
    target: {
      files: [file],
    },
  }) => {
    return uploadMutation({ variables: { file } });
  };

  render() {
    return (
      <Mutation
        context={{ hasUpload: true }}
        mutation={UPLOAD_AVATAR}
        update={(cache, { data: { uploadAvatar } }) => {
          const { avatars } = cache.readQuery({ query: GET_AVATARS, variables: { type: 'TYPE_CUSTOM' } });
          cache.writeQuery({
            query: GET_AVATARS,
            variables: { type: 'TYPE_CUSTOM' },
            data: { avatars: avatars.concat([uploadAvatar]) },
          });
        }}
      >
        {(mutate) => (
          <React.Fragment>
            <VisuallyHiddenInput id="file" type="file" onChange={this.upload(mutate)} />
            <Button
              as="label"
              htmlFor="file"
              alignItems="center"
              justifyContent="center"
              bg="gray200"
              color="secondary"
            >
              <PlusIcon />
            </Button>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

export default UploadAvatar;
