import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { Flex } from 'rebass';
import { withRouter } from 'react-router';

import { Button } from 'src/components/elements/Button';
import Input from 'src/components/elements/InputSearchbar';
import SearchIcon from 'assets/svg/search.svg';

const SearchIconStyled = styled(SearchIcon)`
  display: inline-block;
  width: auto;
  height: 1.5em;
  vertical-align: top;
  fill: currentColor;
  stroke-width: 0;
  stroke: currentColor;
`;

class Searchbar extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    searchTerm: '',
  };

  search = e => {
    e.preventDefault();
    this.props.history.push(`/recherche/${this.state.searchTerm}`);
  };

  render() {
    const { t } = this.props;
    const { searchTerm } = this.state;

    return (
      <Flex as="form" onSubmit={this.search} alignItems="center" css="width: 100%; max-width: 43rem;">
        <Input
          sm
          css="flex: 1 1 100%; margin-right: 1rem"
          placeholder={t('header.searchPlaceholder')}
          type="search"
          name="search"
          value={searchTerm}
          onChange={e => this.setState({ searchTerm: e.target.value })}
          results={5}
        />
        <Button secondary smIcon css="flex: 0 0 auto;" type="submit">
          <SearchIconStyled />
        </Button>
      </Flex>
    );
  }
}

export default withRouter(withNamespaces()(Searchbar));
