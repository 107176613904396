import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import { svgStyle, visuallyHidden } from 'src/styles/helpers';
import CheckSvg from 'assets/svg/check.svg';
import CloseSvg from 'assets/svg/close.svg';

const CheckIcon = styled(CheckSvg)`
  ${svgStyle};
  width: 1.4rem;
  height: 1rem;
`;

const CloseIcon = styled(CloseSvg)`
  ${svgStyle};
  width: 1.4rem;
  height: 1rem;
`;

const Button = styled.div`
  position: relative;
  display: block;
  width: 6.4rem;
  height: 3rem;
  border: 1px solid #e7e5e5;
  border-radius: 14px;
  background-color: ${props => (props.isChecked ? '#e5fbf8' : '#ffe5c7')};
  transition: background-color 225ms ease;
`;

const Input = styled.input`
  ${visuallyHidden};
`;

const Indicateur = styled(Flex)`
  position: absolute;
  top: 0.1rem;
  left: ${props => (props.isChecked ? 'calc(100% - 2.7rem)' : '.1rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  background-color: ${props => (props.isChecked ? '#00d3be' : '#ffad4d')};
  color: #fff;
  transition: background-color 225ms ease, left 225ms ease;
`;

class Toggle extends PureComponent {
  render() {
    const { name, checked, onChange, defaultLabel, checkedLabel } = this.props;
    return (
      <Flex as="label" htmlFor={name} alignItems="center" css="cursor: pointer;">
        <Button isChecked={checked}>
          <Input css={visuallyHidden} type="checkbox" id={name} name={name} onChange={onChange} checked={!!checked} />
          <Indicateur isChecked={checked}>{checked ? <CheckIcon /> : <CloseIcon />}</Indicateur>
        </Button>
        <Text pl={1} fontSize="1.4rem" fontFamily="heading" fontWeight="medium">
          {checked ? checkedLabel : defaultLabel}
        </Text>
      </Flex>
    );
  }
}

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  defaultLabel: PropTypes.string.isRequired,
  checkedLabel: PropTypes.string.isRequired,
};

export default Toggle;
