import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';
import Heading from 'src/components/typography/Heading';
import { theme } from 'src/styles/theme';

const Container = styled(Flex)`
  position: relative;
  width: 100%;
  max-width: 18rem;
  min-height: 15rem;
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 1rem;
    left: 1rem;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    background-color: rgba(162, 239, 232, 0.3);
  }
`;

const Inner = styled(Flex)`
  position: relative;
  z-index: 2;
  width: inherit;
  min-height: inherit;
  border-radius: 0.4rem;
  text-align: center;
  box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);

  h2 span {
    color: ${theme.colors.secondary};
  }
`;

class Counter extends PureComponent {
  static propTypes = {
    icon: PropTypes.any.isRequired,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string.isRequired,
  };

  render() {
    const { icon, label } = this.props;
    return (
      <Container>
        <Inner py={'1.4rem'} px=".2rem" flexDirection="column" justifyContent="center" bg="white" color="primary">
          {icon}
          <Flex alignItems="center" justifyContent="center" pt="1.4rem" css="flex: 0 0 auto;">
            <Heading
              fontSize={['1.2rem', '1.2rem', '1.5rem']}
              textAlign="center"
              dangerouslySetInnerHTML={{ __html: label }}
            />
          </Flex>
        </Inner>
      </Container>
    );
  }
}

export default Counter;
