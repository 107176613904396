import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonStyled = styled.button`
  padding: 0.6rem 0.4rem;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: 0;
  &:hover {
    opacity: 0.7;
  }
  ${props =>
    props.open &&
    `
    &:hover {
      opacity: 0.7;
    }
  `};
`;

const HamburgerBox = styled.span`
  width: 2.6rem;
  height: 2.2rem;
  display: inline-block;
  position: relative;
`;

const HamburgerInner = styled.span`
  display: block;
  top: 50%;
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  ${props =>
    props.open &&
    `
  background-color: #000;
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  `} &,
  &::before,
  &::after {
    width: 2.6rem;
    height: 0.2rem;
    background-color: #000;
    border-radius: 0.2rem;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    ${props =>
      props.open &&
      `
    background-color: #000;
    `};
  }
  &::before {
    top: -0.9rem;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    ${props =>
      props.open &&
      `
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    `};
  }
  &::after {
    bottom: -0.9rem;
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${props =>
      props.open &&
      `
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    `};
  }
`;

const Hamburger = props => (
  <ButtonStyled type="button" onClick={props.onClick} open={props.open}>
    <HamburgerBox>
      <HamburgerInner open={props.open} />
    </HamburgerBox>
  </ButtonStyled>
);

Hamburger.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool,
};

export default Hamburger;
