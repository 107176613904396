import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';

const SelectContainer = styled.div`
  position: relative;
  display: inline-block;
  background-color: ${theme.colors.white};
  ${props => props.css};
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 0.4rem);
    right: 1.2rem;
    display: block;
    width: 1.4rem;
    height: 0.8rem;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 8.18'%3E%3Ctitle%3Echevron-down%3C/title%3E%3Cpath d='M.19,1.1a.66.66,0,0,1,.92,0h0L7.53,7.53,14,1.1a.67.67,0,0,1,.93.16.68.68,0,0,1,0,.77L8,8.9a.66.66,0,0,1-.92,0h0L.19,2a.66.66,0,0,1,0-.92Z' transform='translate(0 -0.91)' style='fill:%235a5a59'/%3E%3C/svg%3E");
  }

  ${props =>
    props.isFocused &&
    `
    &::after {
      transform: rotate(180deg);
    }
  `};
`;

const SelectStyled = styled.select`
  appearance: none;
  width: 100%;
  padding: 1.1rem 7.8rem 1.1rem 1.2rem;
  margin: 0;
  border: 1px solid ${theme.colors.border};
  border-radius: 0.4rem;
  background: transparent;
  color: ${theme.colors.placeholder};
  font-family: ${theme.fonts.base};
  font-size: 1.4rem;
  font-weight: ${theme.fontWeights.normal};
  cursor: pointer;
  outline: none;
  &:disabled {
    background-color: ${theme.colors.gray200};
  }

  ${props =>
    props.hasValue &&
    `
  color: ${theme.colors.text};
  `};
`;

class Select extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    default: PropTypes.string,
    options: PropTypes.array,
  };

  render() {
    const { placeholder, defaultValue, options, css, ...others } = this.props;

    return (
      <SelectContainer css={css}>
        <SelectStyled
          {...others}
          value={others.multiple ? others.value || [placeholder || placeholder] : others.value || placeholder}
          hasValue={others.value || defaultValue}
        >
          {placeholder && (
            <option hidden disabled>
              {placeholder}
            </option>
          )}
          {defaultValue && <option value="">{defaultValue}</option>}
          {options &&
            options.map(subject => (
              <option key={subject.id || 0} value={subject.id}>
                {subject.name}
              </option>
            ))}
        </SelectStyled>
      </SelectContainer>
    );
  }
}

export default Select;
