import React from 'react';
import PropTypes from 'prop-types';
import { Heading as HeadingRebass } from 'rebass';
import { fontSizeBase, modularScale } from 'src/styles/theme';

const fontStyles = {
  h1: fontSizeBase * modularScale * modularScale * modularScale * modularScale + 'rem',
  h2: fontSizeBase * modularScale * modularScale * modularScale + 'rem',
  h3: fontSizeBase * modularScale * modularScale + 'rem',
  h4: fontSizeBase * modularScale + 'rem',
  h5: ['1.4rem', '1.4rem', '1.6rem'],
  h6: fontSizeBase / modularScale + 'rem',
};

const Heading = ({ color, fontSize, fontWeight, ...others }) => (
  <HeadingRebass
    color={color || '#3B3B3B'}
    fontSize={fontSize || fontStyles[others.as]}
    fontWeight={fontWeight || 'semibold'}
    {...others}
  />
);

Heading.propTypes = {
  as: PropTypes.string,
};

Heading.defaultProps = {
  as: 'h2', // like Heading from Rebass
};

export default Heading;
