import styled from 'styled-components';
import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';

import Badge from 'assets/svg/badge.svg';
import Chain from 'assets/svg/chain.svg';
import ChronoSvg from 'assets/svg/chrono.svg';
import Correct from 'assets/svg/correct.svg';
import Duel from 'assets/svg/duel.svg';
import Retry from 'assets/svg/retry.svg';
import TrophySvg from 'assets/svg/trophy.svg';

export const BadgeIcon = styled(Badge)`
  ${svgStyle};
  height: 4.6rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 5.6rem;
  }
`;

export const ChainIcon = styled(Chain)`
  ${svgStyle};
  height: 4.6rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 5.6rem;
  }
`;

export const ChronoIcon = styled(ChronoSvg)`
  ${svgStyle};
  height: 4.6rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 5.6rem;
  }
`;

export const DuelIcon = styled(Duel)`
  ${svgStyle};
  height: 4.6rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 5.6rem;
  }
`;

export const RetryIcon = styled(Retry)`
  ${svgStyle};
  height: 4.6rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 5.2rem;
  }
`;

export const TrophyIcon = styled(TrophySvg)`
  ${svgStyle};
  height: 4.6rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 5.2rem;
  }
`;

export const CorrectIcon = styled(Correct)`
  ${svgStyle};
  height: 4.6rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 5.2rem;
  }
`;
