import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { theme, breakpoints } from 'src/styles/theme';

const getSize = ({ xs, sm }) => {
  if (xs)
    return `
    font-size: 1.4rem;
    @media (min-width: ${breakpoints.md}) {
      font-size: 1.6rem;
    }
  `;

  if (sm)
    return `
    font-size: 1.4rem;
    @media (min-width: ${breakpoints.md}) {
      font-size: 2rem;
    }
  `;

  return `
    font-size: 1.8rem;
    @media (min-width: ${breakpoints.md}) {
      font-size: 2.6rem;
    }
  `;
};

const getWeight = ({ normal, medium }) => {
  if (normal)
    return `
    font-weight: normal;
  `;

  if (medium)
    return `
    font-weight: ${theme.fontWeights.medium};
  `;

  return `
    font-weight: normal;
    @media (min-width: ${breakpoints.md}) {
      font-weight: ${theme.fontWeights.semibold};
    }
  `;
};

const Label = styled(Box)`
  font-family: ${theme.fonts.heading};
  ${props => getSize(props)};
  ${props => getWeight(props)};
  ${props => props.css};
`;

class Title extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    as: PropTypes.string,
    icon: PropTypes.any,
    sm: PropTypes.bool,
    normal: PropTypes.bool,
  };

  render() {
    const { children, as, icon, xs, sm, normal, medium, ...others } = this.props;
    return (
      <Flex {...others} as={as || 'h2'} alignItems="center">
        <Flex alignItems="center" css="flex: 0 0 auto;">
          {icon}
        </Flex>
        <Label as="span" pl={icon ? 1 : 0} xs={xs} sm={sm} normal={normal} medium={medium}>
          {children}
        </Label>
      </Flex>
    );
  }
}

export default Title;
