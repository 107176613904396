import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Content } from 'src/components/utilities/Containers';
import { LinkButton } from 'src/components/elements/Button';
import { Text } from 'rebass';
import FolderList from 'src/components/blocks/FolderList';
import Title from 'src/components/typography/Title';
import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';
import SubscriptionSvg from 'assets/svg/chain.svg';

const SubscriptionIcon = styled(SubscriptionSvg)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

class UserFolders extends PureComponent {
  render() {
    const { folders } = this.props;

    return (
      <Content mt={3}>
        <Title sm icon={<SubscriptionIcon />}>
          Mes abonnements
        </Title>
        <FolderList data={folders} />
        {folders.length >= 3 && (
          <Text pt={1} textAlign="center">
            <LinkButton to="/" css="width: 100%; max-width: 26rem;">
              Voir tous mes abonnements
            </LinkButton>
          </Text>
        )}
      </Content>
    );
  }
}

UserFolders.propTypes = {
  folders: PropTypes.array,
};

export default UserFolders;
