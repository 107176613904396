import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldError } from 'src/utils/form';

import { Box, Flex, Text } from 'rebass';
import { Button } from 'src/components/elements/Button';
import { Grid, Col } from 'src/components/utilities/Grids';
import AvatarList from 'src/components/blocks/AvatarList';
import CustomSelect from 'src/components/elements/CustomSelect';
import FormRow from 'src/components/elements/FormRow';
import Layout from 'src/layouts/Onboarding';
import { InformationsTeacherIcon } from './../Icons';

const formValidate = values => {
  let errors = {};

  if (!values.avatar) {
    errors.avatar = 'Tu dois renseigner choisir un avatar.';
  }

  if (!values.schoolSubjects) {
    errors.schoolSubjects = 'Tu dois renseigner au moins une discipline.';
  }

  return errors;
};

class Teacher extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    onSubmit: PropTypes.func,
    avatars: PropTypes.object,
    currentAvatar: PropTypes.string,
    currentSchoolSubjects: PropTypes.array,
    customAvatars: PropTypes.object,
    removeAvatar: PropTypes.func,
    subjects: PropTypes.object,
    updateUser: PropTypes.func,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const {
      t,
      onSubmit,
      avatars,
      currentAvatar,
      currentSchoolSubjects,
      customAvatars,
      removeAvatar,
      subjects,
      updateUser,
      user,
    } = this.props;

    return (
      <Layout
        title={user.hasBeenReset ? t('onboarding.teacher.resetTitle') : t('onboarding.teacher.welcome')}
        illustration={<InformationsTeacherIcon />}
      >
        <Flex py={3} alignItems="center" css="flex: 1 1 auto; width: 100%;">
          <Box width={1 / 1} css="max-width: 54rem; margin: auto;">
            <Text as="h2" pb="5rem" fontSize={['1.6rem', '1.6rem', '1.6rem']} textAlign="center">
              <Text
                fontWeight="semibold"
                dangerouslySetInnerHTML={{
                  __html: t('onboarding.teacher.hello', { firstname: user.firstname, username: user.username }),
                }}
              />
              {user.schools.length > 0 && (
                <Text pt=".8rem" fontWeight="normal">
                  {t('onboarding.teacher.schools', { count: user.schools.length })}
                </Text>
              )}
              {user.schools.length > 0 && (
                <Text as="ul" mt=".8rem" fontWeight="normal" css="list-style: none; padding-left: 0;">
                  {user.schools.map(school => (
                    <li key={school.id}>{school.name}</li>
                  ))}
                </Text>
              )}
            </Text>
            <Form
              onSubmit={data => onSubmit(data, updateUser)}
              initialValues={{
                avatar: currentAvatar,
                schoolSubjects: currentSchoolSubjects,
              }}
              keepDirtyOnReinitialize={true}
              validate={formValidate}
            >
              {({ handleSubmit, submitting, invalid }) => (
                <Grid as="form" onSubmit={handleSubmit}>
                  <Col width={1 / 1} pb={3}>
                    <Field name="schoolSubjects" disabled={subjects.loading}>
                      {({ input }) => {
                        const options =
                          (subjects.data.subjects &&
                            subjects.data.subjects.length > 0 &&
                            subjects.data.subjects.map(subjects => ({
                              label: subjects.name,
                              value: subjects.id,
                            }))) ||
                          [];

                        return (
                          <FormRow
                            id="schoolSubjects"
                            label={t('schoolSubjectTaught')}
                            description={t('schoolSubjectTaughtDescription')}
                            field={
                              <CustomSelect
                                {...input}
                                defaultValue={input.value}
                                isMulti={true}
                                isClearable={false}
                                isLoading={subjects.loading}
                                options={options}
                                placeholder={t('searchInTheList')}
                                truncateLabel={t('schoolSubjectLowercase', {
                                  count: input.value ? input.value.length : 0,
                                })}
                              />
                            }
                            error={<FieldError name="schoolSubject" />}
                          />
                        );
                      }}
                    </Field>
                  </Col>
                  {avatars.data.avatars.length > 0 && (
                    <Col width={1 / 1} pb={3}>
                      <Box pb={4}>
                        <Text
                          pb={2}
                          fontSize={['1.4rem', '1.4rem', '1.4rem', '1.2rem']}
                          fontWeight={['semibold', 'semibold', 'semibold', 'normal']}
                        >
                          {t('chooseYourAvatar')}
                        </Text>
                        <Field name="avatar" required={true}>
                          {({ input }) => (
                            <AvatarList
                              avatars={avatars.data.avatars}
                              onAvatarClick={avatar => input.onChange(avatar.id)}
                              selectedAvatar={input.value}
                            />
                          )}
                        </Field>
                        <Field name="avatar" required={true}>
                          {({ input }) => (
                            <AvatarList
                              avatars={customAvatars.data.avatars}
                              onAvatarClick={avatar => input.onChange(avatar.id)}
                              onRemoveAvatar={avatar => {
                                if (input.value === avatar.id) {
                                  input.onChange(avatars.data.avatars[0].id);
                                }
                                removeAvatar({
                                  variables: { avatarId: avatar.id },
                                });
                              }}
                              selectedAvatar={input.value}
                              canUploadAvatar={true}
                            />
                          )}
                        </Field>
                      </Box>
                    </Col>
                  )}
                  <Col width={1 / 1} justifyContent="center">
                    <Button type="submit" disabled={submitting || invalid} css="width: 100%; max-width: 22rem;">
                      {t('validate')}
                    </Button>
                  </Col>
                </Grid>
              )}
            </Form>
          </Box>
        </Flex>
      </Layout>
    );
  }
}

export default withNamespaces()(Teacher);
