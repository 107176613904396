import Link from 'src/components/blocks/Drawer/Link';
import Title from 'src/components/blocks/Drawer/Title';
import { breakpoints, theme } from 'src/styles/theme';
import styled from 'styled-components';
import {
  AddClassroomIcon,
  AddCollectionIcon,
  AddFolderIcon,
  AddressBookIcon,
  BadgeIcon,
  ChainCheckIcon,
  ChainIcon,
  ChevronIcon,
  ClassroomIcon,
  CollectionIcon,
  DuelIcon,
  FavoritesIcon,
  HelpIcon,
  HomeIcon,
  RecommendationIcon,
  RetryIcon,
  SettingsIcon,
  TrophyIcon
} from './Icons';
import Separator from './Separator';

const Drawer = styled.nav`
  overflow-y: auto;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 32rem;
  height: 100vh;
  background-color: ${theme.colors.primaryDark};
  transform: perspective(1px);

  @media (max-width: ${breakpoints.xlPlus}) {
    opacity: ${props => (props.open ? 1 : 0)};
  }

  @media (min-width: ${breakpoints.xxl}) {
    width: 34rem;
  }
`;

Drawer.Link = Link;
Drawer.Title = Title;
Drawer.AddCollectionIcon = AddCollectionIcon;
Drawer.AddFolderIcon = AddFolderIcon;
Drawer.ClassroomIcon = ClassroomIcon;
Drawer.AddClassroomIcon = AddClassroomIcon;
Drawer.ChainIcon = ChainIcon;
Drawer.ChainCheckIcon = ChainCheckIcon;
Drawer.ChevronIcon = ChevronIcon;
Drawer.CollectionIcon = CollectionIcon;
Drawer.DuelIcon = DuelIcon;
Drawer.FavoritesIcon = FavoritesIcon;
Drawer.HelpIcon = HelpIcon;
Drawer.HomeIcon = HomeIcon;
Drawer.RecommendationIcon = RecommendationIcon;
Drawer.RetryIcon = RetryIcon;
Drawer.Separator = Separator;
Drawer.AddressBookIcon = AddressBookIcon;
Drawer.SettingsIcon = SettingsIcon;
Drawer.TrophyIcon = TrophyIcon;
Drawer.BadgeIcon = BadgeIcon;

export default Drawer;
