import AddSvg from 'assets/svg/add.svg';
import CheckSvg from 'assets/svg/checked-chip.svg';
import CopySvg from 'assets/svg/copy.svg';
import InformationsTeacherSvg from 'assets/svg/onboarding-informations-teacher.svg';
import InformationsSvg from 'assets/svg/onboarding-informations.svg';
import ProfileSvg from 'assets/svg/onboarding-profile.svg';
import StatisticsSvg from 'assets/svg/statistic.svg';
import SearchSvg from 'assets/svg/search.svg';
import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';
import styled from 'styled-components';

export const AddIcon = styled(AddSvg)`
  ${svgStyle};
  width: 3.2rem;
  height: 3.2rem;
`;

export const CheckIcon = styled(CheckSvg)`
  ${svgStyle};
  width: 3.2rem;
  height: 3.2rem;
`;

export const InformationsIcon = styled(InformationsSvg)`
  ${svgStyle};
  width: 100%;
  max-width: 21.6rem;
  height: 15.9rem;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 57.4rem;
    height: 100%;
  }
`;

export const InformationsTeacherIcon = styled(InformationsTeacherSvg)`
  ${svgStyle};
  width: 100%;
  max-width: 21.6rem;
  height: 15.9rem;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 57.4rem;
    height: 100%;
  }
`;

export const ProfileIcon = styled(ProfileSvg)`
  ${svgStyle};
  width: 100%;
  max-width: 21.6rem;
  height: 15.9rem;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 57.4rem;
    height: 100%;
  }
`;

export const SearchIcon = styled(SearchSvg)`
  ${svgStyle};
  width: 5rem;
  height: 5rem;

  @media (min-width: ${breakpoints.lg}) {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

export const SearchIconBtn = styled(SearchSvg)`
  display: inline-block;
  width: auto;
  height: 1.5em;
  vertical-align: top;
  fill: currentColor;
  stroke-width: 0;
  stroke: currentColor;
`;

export const CopyIcon = styled(CopySvg)`
  ${svgStyle};
  width: 3rem;
  height: 3rem;
  fill: none;
  stroke-width: 1;
  stroke: currentColor;
`;

export const StatisticsIcon = styled(StatisticsSvg)`
  ${svgStyle};
  width: 3.2rem;
  height: 3.2rem;
`;
