import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import ReactModal from 'react-modal';
import { theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import CloseSvg from 'assets/svg/close.svg';

const CloseIcon = styled(CloseSvg)`
  ${svgStyle};
  width: 1.2rem;
  height: 1.2rem;
`;

const modalStyles = {
  overlay: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(80,80,80,0.56)',
  },
  content: {
    position: 'static',
    overflow: 'initial',
    width: '100%',
    maxWidth: '70rem',
    padding: '0',
    border: 'none',
    backgroundColor: 'transparent',
    inset: 'initial',
  },
};

const ModalInner = styled(Box)`
  position: relative;
  width: 100%;
  max-width: 65rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
`;

const CloseButton = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.secondary};
  }
`;

function mergeModalStyle(newStyle = {}) {
  return {
    overlay: modalStyles.overlay,
    content: {
      ...modalStyles.content,
      ...newStyle,
    },
  };
}

class Modal extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    style: PropTypes.object,
    shouldCloseOnEsc: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool,
    showCloseButton: PropTypes.bool,
  };

  static defaultProps = {
    shouldCloseOnEsc: true,
    shouldCloseOnOverlayClick: true,
    showCloseButton: true,
  };

  render() {
    const { children, style, ...others } = this.props;

    return (
      <ReactModal {...others} style={mergeModalStyle(style)}>
        <Box p={[1, 2, 2]}>
          <ModalInner>
            {others.showCloseButton && (
              <CloseButton
                as="button"
                type="button"
                alignItems="center"
                justifyContent="center"
                onClick={others.onRequestClose}
              >
                <CloseIcon />
              </CloseButton>
            )}
            {children}
          </ModalInner>
        </Box>
      </ReactModal>
    );
  }
}

export default Modal;
