import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { Box, Flex, Text } from 'rebass';
import { theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import ChevronSvg from 'assets/svg/chevron-down.svg';

const customStyles = {
  control: (base, state) => ({
    ...base,
    fontSize: '1.4rem',
    boxShadow: 'none',
    borderRadius: state.isFocused ? '.4rem .4rem 0 0' : '.4rem',
    border: `1px solid ${theme.colors.border}`,
    cursor: 'pointer',
    '&:hover': { borderColor: theme.colors.border },
  }),
  indicatorsContainer: () => ({
    // none of react-select's styles are passed to <Control />
    width: '4rem',
    height: '3.8rem',
  }),
  dropdownIndicator: () => ({
    // none of react-select's styles are passed to <Control />
    padding: 0,
  }),
  menu: base => ({
    ...base,
    overflow: 'hidden',
    padding: 0,
    margin: 0,
    border: `1px solid ${theme.colors.border}`,
    borderTop: 'none',
    borderRadius: '0 0 .4rem .4rem',
    boxShadow: 'none',
  }),
  menuList: base => ({
    ...base,
    padding: 0,
  }),
  option: (base, state) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    minHeight: '4rem',
    padding: state.isMulti ? '0' : '.8rem 1.2rem',
    borderBottom: `1px solid ${theme.colors.border}`,
    backgroundColor: state.isSelected || state.isFocused ? '#F6F7FC' : '#FFF',
    color: '#5A5A59',
    fontSize: '1.4rem',
    cursor: 'pointer',
    '&:last-child': { borderBottom: 'none' },
    '&:hover, &:focus, &:active': { backgroundColor: '#F6F7FC' },
  }),
  placeholder: () => ({
    color: theme.colors.placeholder,
  }),
};

const ChevronIcon = styled(ChevronSvg)`
  ${svgStyle};
  width: 1.5rem;
  height: 1rem;
  transition: transform 225ms ease;
  ${props => props.focused && `transform: rotate(180deg);`};
`;

const Checkbox = styled(Box)`
  width: 1.4rem;
  height: 1.4rem;
  border: 0.3rem solid ${theme.colors.white};
  box-shadow: 0px 0px 0px 1px rgba(209, 209, 209, 1);
  background-color: ${props => (props.isSelected ? theme.colors.secondary : '#FFF')};
`;

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Flex alignItems="center" justifyContent="center" css="width: 4rem; height: 4rem;">
          <ChevronIcon focused={props.isFocused ? 'true' : null} />
        </Flex>
      </components.DropdownIndicator>
    )
  );
};

const Option = props => {
  if (props.isMulti) {
    return (
      <components.Option {...props}>
        <Flex alignItems="center" justifyContent="center" css="width: 4rem; height: 4rem;">
          <Checkbox isSelected={props.isSelected} />
        </Flex>
        <Box>{props.label}</Box>
      </components.Option>
    );
  }

  return <components.Option {...props} />;
};

const Value = styled(Text)`
  &:not(:last-child)::after {
    content: ', ';
  }
`;

const MultiValueContainer = props => {
  return (
    <Value as="span" {...props}>
      {props.data.label}
    </Value>
  );
};

const ValueContainer = props => {
  if (props.selectProps && props.selectProps.value && props.selectProps.value.length > 0) {
    return (
      <components.ValueContainer {...props}>
        <Box as="span">
          {props.selectProps.value.length} {props.selectProps.truncateLabel}
        </Box>
        {props.children[1]}
      </components.ValueContainer>
    );
  }
  return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>;
};

class CustomSelect extends PureComponent {
  render() {
    return (
      <Select
        {...this.props}
        styles={customStyles}
        openMenuOnFocus={true}
        openMenuOnClick={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isSearchable={false}
        components={{ DropdownIndicator, Option, MultiValueContainer, ValueContainer }}
        theme={theme => ({
          ...theme,
          borderRadius: '.4rem',
        })}
      />
    );
  }
}

export default CustomSelect;
