import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldError } from 'src/utils/form';

import { Box, Flex, Text } from 'rebass';
import { Col, Grid } from 'src/components/utilities/Grids';
import { Button } from 'src/components/elements/Button';
import { InformationsIcon } from './../Icons';
import FormRow from 'src/components/elements/FormRow';
import Layout from 'src/layouts/Onboarding';
import NavigationButton from 'src/components/elements/NavigationButton';
import Select from 'src/components/elements/Select';

const formValidate = values => {
  let errors = {};

  if (!values.schoolLevel) {
    errors.schoolLevel = 'Tu dois renseigner un niveau.';
  } else if (!values.schoolDegree) {
    errors.schoolDegree = 'Tu dois renseigner un degré.';
  }

  return errors;
};

class Student extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    onSubmit: PropTypes.func,
    currentSchoolDegree: PropTypes.string,
    currentSchoolLevel: PropTypes.string,
    filteredDegrees: PropTypes.array,
    hasSchool: PropTypes.bool,
    levels: PropTypes.object,
    updateUser: PropTypes.func,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const {
      t,
      onSubmit,
      currentSchoolDegree,
      currentSchoolLevel,
      filteredDegrees,
      hasSchool,
      levels,
      updateUser,
      user,
    } = this.props;

    return (
      <Layout
        title={user.hasBeenReset ? t('onboarding.student.resetTitle') : t('onboarding.student.welcome')}
        header={
          <Grid justifyContent="center" pt={[2, 2, 3]}>
            <Col width={[1 / 1, 1 / 1, 1 / 2]} justifyContent={['center', 'center', 'flex-end']} pb={[2, 2, 3]}>
              <NavigationButton type="button" current={true} number="1" label={t('yourInformations')} />
            </Col>
            <Col width={[1 / 1, 1 / 1, 1 / 2]} justifyContent={['center', 'center', 'flex-start']} pb={[2, 2, 3]}>
              <NavigationButton type="button" number="2" label={t('yourProfile')} />
            </Col>
          </Grid>
        }
        illustration={<InformationsIcon />}
      >
        <Flex py={3} alignItems="center" justifyContent="center" css="flex: 1 1 auto;">
          <Box>
            <Text pb={3} fontSize={['1.6rem', '1.6rem', '1.6rem']} fontWeight="semibold" textAlign="center">
              <Text as="h2" fontSize={['1.6rem', '1.6rem', '1.6rem']} fontWeight="semibold">
                {t('onboarding.student.hello', { firstname: user.firstname, username: user.username })}
              </Text>
              {user.schools.length > 0 && (
                <Text as="h3" mt={1} fontSize={['1.6rem', '1.6rem', '1.6rem']} fontWeight="semibold">
                  {t('onboarding.student.schools', { schools: user.schools.map(school => school.name).join(', ') })}
                </Text>
              )}
            </Text>
            <Box css="width: 100%; max-width: 32rem; margin: auto;">
              <Form
                onSubmit={data => onSubmit(data, updateUser)}
                initialValues={{
                  schoolLevel: currentSchoolLevel,
                  schoolDegree: currentSchoolDegree,
                }}
                keepDirtyOnReinitialize={true}
                validate={formValidate}
              >
                {({ handleSubmit, submitting, invalid }) => (
                  <Grid as="form" onSubmit={handleSubmit} css="max-width: 42.8rem; margin: auto;">
                    {levels.data.levels &&
                      levels.data.levels.length > 0 && (
                        <Col width={1 / 1} pb={3}>
                          <Field name="schoolLevel" type="select">
                            {({ input }) => (
                              <FormRow
                                id="schoolLevel"
                                label={t('yourLevel')}
                                field={
                                  <Select
                                    id="schoolLevel"
                                    {...input}
                                    disabled={hasSchool}
                                    placeholder={t('select')}
                                    options={levels.data.levels}
                                    required={true}
                                    css="width: 100%;"
                                  />
                                }
                                error={<FieldError name="schoolLevel" />}
                              />
                            )}
                          </Field>
                        </Col>
                      )}
                    <Col width={1 / 1} pb={3}>
                      <Field name="schoolDegree" type="select">
                        {({ input }) => (
                          <FormRow
                            id="schoolDegree"
                            label="Ton degré"
                            field={
                              <Select
                                id="schoolDegree"
                                {...input}
                                placeholder={t('select')}
                                options={filteredDegrees}
                                required={true}
                                css="width: 100%;"
                              />
                            }
                            error={<FieldError name="schoolDegree" />}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col width={1 / 1} justifyContent="center">
                      <Button type="submit" disabled={submitting || invalid} css="width: 100%; max-width: 22rem;">
                        {t('continue')}
                      </Button>
                    </Col>
                  </Grid>
                )}
              </Form>
            </Box>
          </Box>
        </Flex>
      </Layout>
    );
  }
}

export default withNamespaces()(Student);
