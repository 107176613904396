import unionBy from 'lodash.unionby';

export function createSchoolDegreeLabel(schoolDegrees) {
  const label = [];
  schoolDegrees.forEach(schoolDegree => {
    label.push(schoolDegree.name);
  });

  return label.join(' et ');
}

export function filteredDegreesWithSubject(degrees, subjectId) {
  return degrees.filter(degree => degree.schoolSubjects.map(schoolSubject => schoolSubject.id).includes(subjectId));
}

export function formatSchoolDegrees(schools) {
  const schoolDegrees = schools.map(school => {
    return school.schoolLevel.schoolDegrees.map(schoolDegree => {
      return { id: schoolDegree.id, name: schoolDegree.name };
    });
  });

  return unionBy(...schoolDegrees, schoolDegree => schoolDegree.id);
}
