import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from '@apollo/client/react/components';
import { adopt } from 'react-adopt';
import { UPDATE_USER } from 'src/queries/users';
import { GET_SUBJECTS } from 'src/queries/subjects';
import { GET_AVATARS, REMOVE_AVATAR } from 'src/queries/avatars';
import { handleSubmissionErrors } from 'src/utils/form';
import Content from './Content';

class TeacherContainer extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
  };

  isContentLoaded = false;

  static formatFormValuesvalues(values) {
    const formattedData = {
      hasBeenReset: false,
    };

    if (values.avatar) {
      formattedData.avatar = { id: values.avatar };
    }

    if (values.schoolSubjects) {
      formattedData.schoolSubjects = values.schoolSubjects.map((schoolSubject) => ({ id: schoolSubject.value }));
    }

    return formattedData;
  }

  onSubmit = (data, updateUser) => {
    const { history } = this.props;
    return updateUser({
      variables: {
        input: TeacherContainer.formatFormValuesvalues(data),
      },
    })
      .then(() => {
        history.push(`/`);
      })
      .catch((errors) => {
        return handleSubmissionErrors(errors);
      });
  };

  render() {
    const { user } = this.props;

    /*eslint-disable */
    const queries = {
      subjects: ({ render }) => <Query query={GET_SUBJECTS}>{render}</Query>,
      avatars: ({ render }) => (
        <Query query={GET_AVATARS} variables={{ type: 'TYPE_TEACHER' }}>
          {render}
        </Query>
      ),
      customAvatars: ({ render }) => (
        <Query query={GET_AVATARS} variables={{ type: 'TYPE_CUSTOM' }}>
          {render}
        </Query>
      ),
      removeAvatar: ({ render }) => (
        <Mutation
          mutation={REMOVE_AVATAR}
          update={(cache, { data: { removeAvatar } }) => {
            const { avatars } = cache.readQuery({ query: GET_AVATARS, variables: { type: 'TYPE_CUSTOM' } });
            cache.writeQuery({
              query: GET_AVATARS,
              variables: { type: 'TYPE_CUSTOM' },
              data: { avatars: avatars.filter((avatar) => avatar.id != removeAvatar.id) },
            });
          }}
        >
          {render}
        </Mutation>
      ),
      updateUser: ({ render }) => <Mutation mutation={UPDATE_USER}>{render}</Mutation>,
    };

    const Composed = adopt(queries);
    /*eslint-enable */

    return (
      <Composed>
        {({ subjects, avatars, customAvatars, removeAvatar, updateUser }) => {
          if (
            (subjects.loading ||
              avatars.loading ||
              customAvatars.loading ||
              removeAvatar.loading ||
              updateUser.loading) &&
            !this.isContentLoaded
          )
            return <div />;
          if (subjects.error || avatars.error || customAvatars.error || removeAvatar.error || updateUser.error)
            return <p>Error :(</p>;
          this.isContentLoaded = true;

          const currentAvatar =
            (user.avatar && user.avatar.id) || (avatars.data.avatars.length > 0 && avatars.data.avatars[0].id);
          const currentSchoolSubjects =
            user.schoolSubjects.length > 0
              ? user.schoolSubjects.map((schoolSubject) => {
                  return { label: schoolSubject.name, value: schoolSubject.id };
                })
              : null;

          return (
            <Content
              onSubmit={this.onSubmit}
              avatars={avatars}
              currentAvatar={currentAvatar}
              currentSchoolSubjects={currentSchoolSubjects}
              customAvatars={customAvatars}
              removeAvatar={removeAvatar}
              subjects={subjects}
              updateUser={updateUser}
              user={user}
            />
          );
        }}
      </Composed>
    );
  }
}

export default TeacherContainer;
