import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Box, Text } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import { breakpoints, theme } from 'src/styles/theme';

class OnboardingLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    header: PropTypes.element,
    illustration: PropTypes.element,
    title: PropTypes.string,
  };

  render() {
    const { children, header, illustration, title } = this.props;

    return (
      <Box
        css={`
          display: flex;
          flex: 1 1 auto;
          min-height: 0;
          background: linear-gradient(
            to right,
            #fff,
            #fff 50%,
            ${theme.colors.gray100} 50%,
            ${theme.colors.gray100} 100%
          );
        `}
      >
        <Content css="display: flex; flex: 1 1 auto; min-height: 0;">
          <Grid css="flex: 1 1 auto; min-height: 0;">
            <Col
              py={4}
              width={[1 / 1, 1 / 1, 1 / 1, 1 / 2]}
              bg="white"
              css={`
                @media (max-width: ${breakpoints.mdPlus}) {
                  order: 2;
                }
              `}
            >
              <Container css="display: flex; flex-direction: column;">
                <Box css="flex: 0 0 auto;">
                  <MediaQuery minWidth={breakpoints.lg}>
                    <Text
                      as="h1"
                      fontFamily="heading"
                      fontSize="2.4rem"
                      fontWeight="medium"
                      textAlign="center"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  </MediaQuery>
                  {header}
                </Box>
                {children}
              </Container>
            </Col>
            <Col
              width={[1 / 1, 1 / 1, 1 / 1, 1 / 2]}
              bg="gray100"
              css={`
                @media (max-width: ${breakpoints.mdPlus}) {
                  order: 1;
                }
              `}
            >
              <Container pb={3} css="text-align: center;">
                <MediaQuery maxWidth={breakpoints.mdPlus}>
                  <Text
                    as="h1"
                    pt={3}
                    pb={4}
                    fontFamily="heading"
                    fontSize="1.6rem"
                    fontWeight="medium"
                    textAlign="center"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </MediaQuery>
                {illustration}
              </Container>
            </Col>
          </Grid>
        </Content>
      </Box>
    );
  }
}

export default OnboardingLayout;
