import React from 'react';
import PropTypes from 'prop-types';
import NavLink from 'react-router-dom/NavLink';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import { theme } from 'src/styles/theme';

const Link = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 7rem;
  padding-right: 4rem;
  color: ${theme.colors.white};
  text-decoration: none;
  transition: background-color 225ms ease;

  &:hover,
  &.selected {
    background-color: ${theme.colors.primaryLight};
  }
`;

const IconBox = styled(Flex)`
  position: relative;
  min-width: 8rem;
  min-height: 7rem;
`;

const Notification = ({ count }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    width="2.2rem"
    bg="secondary"
    color="white"
    fontSize="1.2rem"
    css={`
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.8rem;
      height: 2.2rem;
      border-radius: 50%;
      font-weight: ${theme.fontWeights.semibold};
    `}
  >
    {count}
  </Flex>
);

Notification.propTypes = {
  count: PropTypes.number,
};

const LinkStyled = ({ onClick, icon, label, notification, showNotificationOnReducedDrawer, ...others }) => (
  <Link {...others} exact={true} activeClassName="selected" onClick={onClick}>
    <IconBox alignItems="center" justifyContent="center" title={label}>
      {icon}
      {showNotificationOnReducedDrawer && !!notification && <Notification count={notification} />}
    </IconBox>
    <Text as="span" color="currentColor" fontFamily="heading" fontSize="1.5rem" fontWeight="medium">
      {label}
    </Text>
    {!!notification && <Notification count={notification} />}
  </Link>
);

LinkStyled.propTypes = {
  to: PropTypes.string,
  as: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.any,
  label: PropTypes.string,
  notification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LinkStyled;
