import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { Text } from 'rebass';
import { Content } from 'src/components/utilities/Containers';
import { LinkButton } from 'src/components/elements/Button';
import CollectionList from 'src/components/blocks/CollectionList';
import Title from 'src/components/typography/Title';
import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';
import CollectionSvg from 'assets/svg/collection.svg';

const CollectionIcon = styled(CollectionSvg)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

class AuthorCollections extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    user: PropTypes.object,
    collections: PropTypes.array,
  };

  render() {
    const { t, user, collections } = this.props;

    return (
      <Content mt={3}>
        <Title sm icon={<CollectionIcon />}>
          {t('mySeries')}
        </Title>
        <CollectionList data={collections} showBuildButton={true} editableCards={true} author={user.id} />
        {collections.length > 0 && (
          <Text pt={1} textAlign="center">
            <LinkButton to="/mes-series" css="width: 100%; max-width: 22rem;">
              {t('seeAllMySeries')}
            </LinkButton>
          </Text>
        )}
      </Content>
    );
  }
}

export default withNamespaces()(AuthorCollections);
