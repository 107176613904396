import styled from 'styled-components';
import AddressBookSvg from 'assets/svg/address-book.svg';
import BadgeSvg from 'assets/svg/badge.svg';
import LogoutSvg from 'assets/svg/logout.svg';
import SettingsSvg from 'assets/svg/settings.svg';
import TrophySvg from 'assets/svg/trophy.svg';
import { svgStyle } from 'src/styles/helpers';

export const AddressBookIcon = styled(AddressBookSvg)`
  ${svgStyle};
  width: 1.4rem;
`;

export const BadgeIcon = styled(BadgeSvg)`
  ${svgStyle};
  width: 1.4rem;
`;

export const LogoutIcon = styled(LogoutSvg)`
  ${svgStyle};
  width: 1.4rem;
`;

export const SettingsIcon = styled(SettingsSvg)`
  ${svgStyle};
  width: 1.4rem;
`;

export const TrophyIcon = styled(TrophySvg)`
  ${svgStyle};
  width: 1.4rem;
`;
