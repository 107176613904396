export function hasAdminRole(user) {
  return user.roles.includes('ROLE_ADMIN');
}

export function hasTeacherRole(user) {
  return (
    user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_HEADTEACHER') || user.roles.includes('ROLE_TEACHER')
  );
}

export function hasRefRole(user) {
  return user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_HEADTEACHER');
}
