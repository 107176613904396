import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import Helmet from 'react-helmet';
import DefaultLayout from 'src/Layout';
import LoadingProvider from 'src/LoadingProvider';

// from https://gist.github.com/avinmathew/e82fe7e757b20cb337d5219e0ab8dc2c
export class Routes extends PureComponent {
  render() {
    let { t, component: Component, layout: Layout, showOnboarding, redirect, title, ...rest } = this.props;
    if (Layout === undefined) {
      Layout = DefaultLayout;
    }

    return (
      <Route
        {...rest}
        render={props =>
          showOnboarding || redirect ? (
            <Redirect to={{ pathname: '/', state: { redirect: false } }} />
          ) : (
            <LoadingProvider>
              <Layout {...rest}>
                {title && (
                  <Helmet>
                    <title>{t(title)}</title>
                  </Helmet>
                )}
                <Component {...props} {...rest} />
              </Layout>
            </LoadingProvider>
          )
        }
      />
    );
  }
}

export const AppRoute = withNamespaces()(Routes);
