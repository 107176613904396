import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import moment from 'moment';
import App from 'src/App';
import 'src/locales/i18n';
moment.locale('fr');

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/serviceWorker.js');
  
}

const mountElement = document.getElementById('root');

ReactDOM.render(<App isLogged={!!mountElement.dataset.isLogged} />, mountElement);
