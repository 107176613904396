import styled, { css } from 'styled-components';
import Link from 'react-router-dom/Link';
import { Button as ButtonRebass } from 'rebass';
import { theme } from 'src/styles/theme';

const getTheme = ({ secondary, dark, disabled }) => {
  if (disabled)
    return `
    color: ${theme.colors.white};
    background-color: #ccc;
    cursor: not-allowed;
    &::before {
      background-color: #ccc;
    };
  `;

  if (secondary)
    return `
    color: ${theme.colors.white};
    background-color: ${theme.colors.secondary};
    &::before {
      background-color: ${theme.colors.secondary};
    };
  `;

  if (dark)
    return `
    color: ${theme.colors.white};
    background-color: #5A5A59;
    &::before {
      background-color: #5A5A59;
    };
  `;

  return `
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
    &::before {
      background-color: ${theme.colors.primary};
    };
  `;
};

const getSize = ({ sm, smIcon, md }) => {
  if (sm)
    return `
    padding-right: 1.71429em;
    padding-left: 1.71429em;
    padding-top: .5em;
    padding-bottom: .5em;
    font-size: 1.4rem;
    line-height: 1.5;
  `;

  if (md)
    return `
    padding-right: 3em;
    padding-left: 3em;
    padding-top: .7em;
    padding-bottom: .7em;
    font-size: 1.8rem;
    line-height: 1.5;
  `;

  if (smIcon)
    return `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 5.6rem;
    height: 3.6rem;
    padding: 0;
  `;

  return `
    padding-right: 1.71429em;
    padding-left: 1.71429em;
    padding-top: .679em;
    padding-bottom: .679em;
    font-size: 1.4rem;
    line-height: 1.5;
  `;
};

const styles = css`
  position: relative;
  z-index: 0;
  display: inline-block;
  border: 1px;
  border-color: transparent;
  font-weight: 600;
  border-radius: 20rem;
  text-align: center;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px transparent;
  font-family: ${theme.fonts.base};
  cursor: pointer;
  outline: 0;
  transition: .2s;
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: #000;
    border-radius: inherit;
    opacity: 0.15;
    transition: inherit;
  }
  &::before {
    bottom: -.8rem;
    right: 0;
    left: 0;
    z-index: -11;
    height: 100%;
    opacity: 0.15;
  }
  &::after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -10;
    opacity: 0;
  }
  ${props =>
    !props.disabled &&
    `
  &:focus,
  &:hover,
  &:active,
  &[aria-pressed="true"],
  &.is-active {
    &::after {
      opacity: .09;
    }
  }
  &:active,
  &[aria-pressed="true"],
  &.is-active {
    transform: translateY(.8rem);
    &::before {
      transform: translateY(-.8rem);
    }
  }
  `}
  ${props => getTheme(props)}
  ${props => getSize(props)}
  ${props => props.css}
`;

export const LinkButton = styled(Link)`
  ${styles};
`;

export const Button = styled(ButtonRebass)`
  ${styles};
`;

export const LabelButton = styled.label`
  ${styles};
`;
