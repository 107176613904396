import React, { PureComponent } from 'react';
import { Container, Content } from 'src/components/utilities/Containers';
import { Box, Text } from 'rebass';

class Errors extends PureComponent {
  render() {
    return (
      <Box width={1 / 1}>
        <Box bg="gray100">
          <Text py={['2rem', '2rem', '6rem']} textAlign="center">
            <Text
              as="h2"
              fontFamily="Montserrat, sans-serif"
              fontSize={['1.6rem', '1.6rem', '3rem']}
              fontWeight="semibold"
            >
              Une erreur est survenue 💥
            </Text>
            <Container pt={[2, 3, 4, 0]} bg="gray100">
              <Content>
                <p>
                  <a href={`${window.location.href}`}>🔄 Recharger la page</a>
                </p>
                <p>
                  <a href={`${process.env.BASENAME}`}>{"🏠 Retourner à l'accueil"}</a>
                </p>
              </Content>
            </Container>
          </Text>
        </Box>
      </Box>
    );
  }
}

export default Errors;
