import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from '@apollo/client/react/components';
import { adopt } from 'react-adopt';
import { GET_DEGREES } from 'src/queries/degrees';
import { GET_LEVELS } from 'src/queries/levels';
import { UPDATE_USER } from 'src/queries/users';
import { handleSubmissionErrors } from 'src/utils/form';
import Profile from './Profile';
import Content from './Content';

class StudentContainer extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
  };

  isContentLoaded = false;

  static formatFormValuesvalues(values) {
    const formattedData = {
      hasBeenReset: false,
    };

    if (values.schoolLevel) {
      formattedData.schoolLevel = { id: values.schoolLevel };
    }

    if (values.schoolDegree) {
      formattedData.schoolDegree = { id: values.schoolDegree };
    }

    return formattedData;
  }

  onSubmit = (data, updateUser) => {
    return updateUser({
      variables: {
        input: StudentContainer.formatFormValuesvalues(data),
      },
    })
      .then(() => {
        this.props.history.push(`/`);
      })
      .catch((errors) => {
        return handleSubmissionErrors(errors);
      });
  };

  render() {
    const { user } = this.props;
    const hasSchool = user.schools.length > 0;

    /*eslint-disable */
    const queries = {
      degrees: ({ render }) => <Query query={GET_DEGREES}>{render}</Query>,
      levels: ({ render }) => <Query query={GET_LEVELS}>{render}</Query>,
      updateUser: ({ render }) => <Mutation mutation={UPDATE_USER}>{render}</Mutation>,
    };

    const Composed = adopt(queries);
    /*eslint-enable */

    return (
      <Composed>
        {({ degrees, levels, updateUser }) => {
          if ((degrees.loading || levels.loading || updateUser.loading) && !this.isContentLoaded) return <div />;
          if (degrees.error || levels.error || updateUser.error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          let filteredDegrees = [];
          if (hasSchool) {
            filteredDegrees = user.schools[0].schoolLevel.schoolDegrees;
          } else if (degrees.data.degrees) {
            filteredDegrees = degrees.data.degrees;
          }
          const currentSchoolLevel =
            (user.schoolLevel && user.schoolLevel.id) || (hasSchool && user.schools[0].schoolLevel.id);
          const currentSchoolDegree = user.schoolDegree && user.schoolDegree.id;

          return (
            <Content
              onSubmit={this.onSubmit}
              currentSchoolDegree={currentSchoolDegree}
              currentSchoolLevel={currentSchoolLevel}
              degrees={degrees}
              filteredDegrees={filteredDegrees}
              hasSchool={hasSchool}
              levels={levels}
              updateUser={updateUser}
              user={user}
            />
          );
        }}
      </Composed>
    );
  }
}

StudentContainer.Profile = Profile;

export default StudentContainer;
