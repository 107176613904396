"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryContext = exports.UserContext = exports.ModalContext = exports.LoadingContext = exports.DrawerContext = void 0;
var React = require("react");
exports.DrawerContext = React.createContext(null);
exports.LoadingContext = React.createContext(null);
exports.ModalContext = React.createContext({
    toggleModal: function () { },
});
exports.UserContext = React.createContext(null);
exports.QueryContext = React.createContext(null);
