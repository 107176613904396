"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var client_1 = require("@apollo/client");
var share_svg_1 = require("assets/svg/share.svg");
var lodash_1 = require("lodash");
var React = require("react");
var rebass_1 = require("rebass");
var Title_1 = require("src/components/typography/Title");
var Containers_1 = require("src/components/utilities/Containers");
var context_1 = require("src/context");
var collections_1 = require("src/queries/collections");
var helpers_1 = require("src/styles/helpers");
var theme_1 = require("src/styles/theme");
var styled_components_1 = require("styled-components");
var CollectionList_1 = require("../CollectionList");
var ShareIcon = (0, styled_components_1.default)(share_svg_1.default)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  height: 2rem;\n\n  @media (min-width: ", ") {\n    min-height: 2.6rem;\n  }\n"], ["\n  ", ";\n  height: 2rem;\n\n  @media (min-width: ", ") {\n    min-height: 2.6rem;\n  }\n"])), helpers_1.svgStyle, theme_1.breakpoints.md);
var ProposedCollections = function (_a) {
    var proposalCollections = _a.proposalCollections;
    var loadingContext = React.useContext(context_1.LoadingContext);
    var _b = (0, client_1.useQuery)(collections_1.GET_COLLECTIONS, {
        variables: {
            withCards: true,
            filterBy: [{ property: 'id', in: proposalCollections.map(function (proposalCollection) { return proposalCollection.collection.id; }) }]
        },
    }), data = _b.data, loading = _b.loading;
    var proposalByTeacher = (0, lodash_1.groupBy)(proposalCollections, function (proposalCollection) { return proposalCollection.teacher.id; });
    var proposalTuple = React.useMemo(function () {
        if (!(data === null || data === void 0 ? void 0 : data.collections))
            return null;
        return Object.values(proposalByTeacher)
            .map(function (proposalCollections) {
            return [
                proposalCollections[0].teacher,
                proposalCollections.map(function (proposalCollection) {
                    return data.collections.results.find(function (collection) { return collection.id === proposalCollection.collection.id; });
                })
            ];
        });
    }, [data === null || data === void 0 ? void 0 : data.collections]);
    React.useEffect(function () {
        if (loading) {
            loadingContext.addLoading();
        }
        else {
            loadingContext.removeLoading();
        }
    }, [loading]);
    return (React.createElement(Containers_1.Content, { mt: 3 },
        React.createElement(rebass_1.Flex, { flexDirection: "column", css: "gap:3rem;" },
            React.createElement(Title_1.default, { sm: true, icon: React.createElement(ShareIcon, null) }, "S\u00E9ries propos\u00E9es"), proposalTuple === null || proposalTuple === void 0 ? void 0 :
            proposalTuple.map(function (_a) {
                var teacher = _a[0], collections = _a[1];
                return (React.createElement(rebass_1.Flex, { key: teacher.id, flexDirection: "column", css: "gap:1rem;" },
                    React.createElement(rebass_1.Text, { color: "#888885", fontSize: ['1rem', '1rem', '1.5rem'], fontWeight: "semibold" }, "S\u00E9ries propos\u00E9es par ".concat(teacher.firstname, " ").concat(teacher.lastname)),
                    collections && React.createElement(CollectionList_1.default, { stateRedirect: { teacher: teacher.id }, data: collections })));
            }))));
};
exports.default = ProposedCollections;
var templateObject_1;
