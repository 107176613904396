"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Col = exports.Grid = void 0;
var React = require("react");
var rebass_1 = require("rebass");
var getWidth = function (width) {
    if (!width) {
        return "auto";
    }
    var widths = [];
    if (!Array.isArray(width)) {
        return "0 0 ".concat(width * 100, "%");
    }
    width.forEach(function (value) {
        widths.push("0 0 ".concat(value * 100, "%"));
    });
    return widths;
};
var Grid = function (_a) {
    var ml = _a.ml, flexWrap = _a.flexWrap, others = __rest(_a, ["ml", "flexWrap"]);
    return React.createElement(rebass_1.Flex, __assign({ ml: ml || -2, 
        // @ts-expect-error
        flexWrap: flexWrap || 'wrap' }, others));
};
exports.Grid = Grid;
var Col = function (_a) {
    var width = _a.width, flex = _a.flex, pl = _a.pl, others = __rest(_a, ["width", "flex", "pl"]);
    return React.createElement(rebass_1.Flex, __assign({ pl: pl || 2, flex: flex || getWidth(width) }, others));
};
exports.Col = Col;
