import AddClassroomSvg from 'assets/svg/add-classroom.svg';
import AddCollectionSvg from 'assets/svg/add-collection.svg';
import AddFolderSvg from 'assets/svg/add-folder.svg';
import AddressBookSvg from 'assets/svg/address-book.svg';
import BadgeSvg from 'assets/svg/badge.svg';
import ChainCheckSvg from 'assets/svg/chain-check.svg';
import ChainSvg from 'assets/svg/chain.svg';
import ChevronDownSvg from 'assets/svg/chevron-down.svg';
import ClassroomSvg from 'assets/svg/classroom.svg';
import CollectionSvg from 'assets/svg/collection.svg';
import DuelSvg from 'assets/svg/duel.svg';
import FavoritesSvg from 'assets/svg/favorites.svg';
import HelpSvg from 'assets/svg/help.svg';
import HomeSvg from 'assets/svg/home.svg';
import RecommendationSvg from 'assets/svg/recommandations.svg';
import RetrySvg from 'assets/svg/retry.svg';
import SettingsSvg from 'assets/svg/settings.svg';
import TrophySvg from 'assets/svg/trophy.svg';
import { svgStyle } from 'src/styles/helpers';
import { theme } from 'src/styles/theme';
import styled from 'styled-components';

export const AddCollectionIcon = styled(AddCollectionSvg)`
  ${svgStyle};
`;

export const AddFolderIcon = styled(AddFolderSvg)`
  ${svgStyle};
`;

export const AddClassroomIcon = styled(AddClassroomSvg)`
display: inline-block;
vertical-align: top;
fill: none;
`;

export const ChainIcon = styled(ChainSvg)`
  ${svgStyle};
`;

export const ChainCheckIcon = styled(ChainCheckSvg)`
  ${svgStyle};
  width: 2.6rem;
  height: 2.6rem;
`;

export const ChevronIcon = styled(ChevronDownSvg)`
  ${svgStyle};
  width: 2.5rem;
  fill: ${theme.colors.secondary};
`;

export const CollectionIcon = styled(CollectionSvg)`
  ${svgStyle};
`;

export const DuelIcon = styled(DuelSvg)`
  ${svgStyle};
`;

export const AddressBookIcon = styled(AddressBookSvg)`
  ${svgStyle};
`;

export const ClassroomIcon = styled(ClassroomSvg)`
  display: inline-block;
  vertical-align: top;
  fill: none;
  stroke: ${theme.colors.white};
  stroke-width: 0.8
`;

export const SettingsIcon = styled(SettingsSvg)`
  ${svgStyle};
`;

export const TrophyIcon = styled(TrophySvg)`
  ${svgStyle};
`;

export const BadgeIcon = styled(BadgeSvg)`
  ${svgStyle};
`;

export const FavoritesIcon = styled(FavoritesSvg)`
  ${svgStyle};
  .favorite-icon-full {
    fill: transparent;
  }
`;

export const HelpIcon = styled(HelpSvg)`
  ${svgStyle};
`;

export const HomeIcon = styled(HomeSvg)`
  ${svgStyle};
`;

export const RecommendationIcon = styled(RecommendationSvg)`
  ${svgStyle};
`;

export const RetryIcon = styled(RetrySvg)`
  ${svgStyle};
`;
