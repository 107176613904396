import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import styled from 'styled-components';
import { TOGGLE_FAVORITE_COLLECTION } from 'src/queries/collections';
import { Box } from 'rebass';
import { theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import FavoriteSvg from 'assets/svg/favorites.svg';
import FavoriteSvgFull from 'assets/svg/favorites-full.svg';

const Button = styled(Box)`
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

export const FavoriteIcon = styled(FavoriteSvg)`
  ${svgStyle};
  height: 2.2rem;
  fill: ${theme.colors.tertiary};
`;

export const FavoriteIconActive = styled(FavoriteSvgFull)`
  ${svgStyle};
  height: 2.2rem;
  fill: ${theme.colors.tertiary};
`;

class FavoriteButton extends PureComponent {
  render() {
    const { collection, isFavorite } = this.props;
    return (
      <Mutation mutation={TOGGLE_FAVORITE_COLLECTION}>
        {(toggleFavoriteCollection) => (
          <Button
            as="button"
            type="button"
            pr="1.2rem"
            onClick={(e) => {
              e.preventDefault();
              toggleFavoriteCollection({ variables: { collection: collection } });
            }}
          >
            {isFavorite ? <FavoriteIconActive /> : <FavoriteIcon />}
          </Button>
        )}
      </Mutation>
    );
  }
}

FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool,
  collection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FavoriteButton;
