import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';

const Row = styled(Flex)`
  width: 100%;
  ${props => props.css};
`;

class FormRow extends PureComponent {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
    field: PropTypes.element.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  };

  render() {
    const { id, label, description, field, error, ...others } = this.props;

    return (
      <Row flexDirection="column" {...others}>
        <Text as="label" fontSize="1.2rem" fontWeight="600" color="#747473" htmlFor={id}>
          {label}
        </Text>
        {description && (
          <Text as="span" pt=".4rem" fontSize="1.2rem" dangerouslySetInnerHTML={{ __html: description }} />
        )}
        <Box width={1 / 1} pt={1} css={others.inputCss}>
          {field}
        </Box>
        {error && (
          <Text pt=".2rem" fontSize="1.2rem" color="error">
            {error}
          </Text>
        )}
      </Row>
    );
  }
}

export default FormRow;
