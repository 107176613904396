import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Grid } from 'src/components/utilities/Grids';
import Avatar from 'src/components/elements/Avatar';
import styled from 'styled-components';
import UploadAvatar from './UploadAvatar';
import RemoveSvg from 'assets/svg/remove-dark.svg';
import { svgStyle } from 'src/styles/helpers';

const RemoveIcon = styled(RemoveSvg)`
  ${svgStyle};
  width: 3.2rem;
  height: 3.2rem;
`;

const RemoveButton = styled.button`
  position: absolute;
  left: 0.3rem;
  bottom: 0.3rem;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

class AvatarList extends PureComponent {
  static propTypes = {
    avatars: PropTypes.array,
    selectedAvatar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onAvatarClick: PropTypes.func,
    onRemoveAvatar: PropTypes.func,
    canUploadAvatar: PropTypes.bool,
  };

  render() {
    const { avatars, selectedAvatar, onAvatarClick, onRemoveAvatar, canUploadAvatar } = this.props;

    return (
      <Grid>
        {avatars.map(avatar => (
          <Col key={avatar.id} flex="0 0 auto" css="position: relative;" pb={2}>
            <Avatar
              avatar={avatar}
              onClick={() => {
                onAvatarClick(avatar);
              }}
              isSelected={selectedAvatar === avatar.id}
              removeButton={
                avatar.type === 'TYPE_CUSTOM' ? (
                  <RemoveButton type="button" onClick={() => onRemoveAvatar(avatar)}>
                    <RemoveIcon />
                  </RemoveButton>
                ) : null
              }
            />
          </Col>
        ))}
        {canUploadAvatar && (
          <Col flex="0 0 auto" pb={2}>
            <UploadAvatar />
          </Col>
        )}
      </Grid>
    );
  }
}

export default AvatarList;
